import axios from 'axios'

/**
|--------------------------------------------------
| @description - axios instance to make axios calls, 
baseURL and headers can be modified by passing argument of this 
function
|--------------------------------------------------
*/
export const API = (customizedHeaders, baseURL) => {
  let url
  if (baseURL) url = baseURL
  else url = process.env.REACT_APP_SERVER
  // console.log(url)
  // console.log(customizedHeaders)
  return axios.create({
    baseURL: url,
    ...(customizedHeaders && { headers: customizedHeaders }),
  })
}
