import React, { useEffect, useState } from 'react'
import {
  Layout,
  Menu,
  Avatar,
  Row,
  Col,
  Typography,
  Badge,
  Button,
  Dropdown,
} from 'antd'
import moment from 'moment'
import { getNotifications, readANotification } from '../../utils/requests'

const { Text, Paragraph } = Typography

function RenderNotifications({ token }) {
  const [notifications, setNotifications] = useState([])

  useEffect(() => {
    ;(async () => {
      // This call is redundant but its ok for now, can refactor on real version (if needed)
      const notifications = await getNotifications(token)
      setNotifications(notifications)
    })()
  }, [])

  return (
    <div
      style={{
        padding: '10px',
        backgroundColor: '#fff',
        boxShadow: '3px 1px 5px -2px #000000',
        width: '300px',
      }}
    >
      <Paragraph strong>Notifications</Paragraph>

      {notifications.length === 0 && (
        <Paragraph style={{ margin: 0 }} className="user-top-info">
          You do not have any notification
        </Paragraph>
      )}

      {notifications.map((notification, index) => {
        return (
          <div
            key={index}
            style={{
              cursor: 'pointer',
              backgroundColor: 'rgba(0, 0, 0, 0.09)', // TODO: add this bgColor, based on condition if the notification is read or not
              padding: 7,
              borderRadius: 3,
              marginBottom: 3,
            }}
            onClick={() => {
              window.location.href = notification.content.url
              readANotification(token, notification._id)
            }}
          >
            <Paragraph style={{ margin: 0 }} className="user-top-info">
              {notification.content.message}
            </Paragraph>
            <Paragraph
              style={{ margin: 0, fontSize: '.5rem' }}
              className="user-top-info"
            >
              {moment(notification.createdAt).fromNow()}
            </Paragraph>
          </div>
        )
      })}
    </div>
  )
}

export default RenderNotifications
