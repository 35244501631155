import React, { useState, useEffect } from 'react'
import {
  Form,
  Input,
  Button,
  Col,
  Row,
  TimePicker,
  DatePicker,
  Typography,
  Select,
  Tooltip,
  Upload,
  message,
} from 'antd'
import { connect, useDispatch } from 'react-redux'
import {
  MinusCircleOutlined,
  PlusOutlined,
  DollarOutlined,
  InfoCircleOutlined,
  VerifiedOutlined,
} from '@ant-design/icons'
import axios from 'axios'
import moment from 'moment'

import { API } from '../api'
import AuthorizationLayout from '../layout/AuthorizationLayout.jsx'

import { getConsultantInfo } from '../redux/user/user.action'
import { updateProfile } from '../redux/auth/auth.action'

const { Paragraph } = Typography

const Feedback = (props) => {
  const { auth, user, getConsultantInfo, updateProfile } = props
  const { tempConsultant } = user

  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)

  useEffect(() => {}, [])

  const submitFeedback = async (vl) => {
    setLoading(true)

    try {
      const response = await API({
        Authorization: `Bearer ${auth.user.token}`,
      }).post(`/api/v1/user/feedback`, { ...vl })

      console.log(response.data)
      message.success('Your feedback has been submitted')
      setLoading(false)
    } catch (err) {
      setLoading(false)
      console.log(err)
    }
  }

  const onFinish = (values) => {
    console.log({
      ...values,
    })
    submitFeedback(values)
  }

  return (
    <AuthorizationLayout>
      <Form
        labelCol={{
          span: 24,
        }}
        onFinish={onFinish}
      >
        <Row
          gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
          style={{
            marginTop: '50px',
            padding: '20px 10px',
            border: '1px solid #e5e5e5',
            borderRadius: '8px',
          }}
        >
          <Col span={24}>
            <h1 style={{ textAlign: 'center' }}>Send us your feedback</h1>

            <Row justify="center">
              <Col xs={24} lg={18}>
                <Form.Item
                  name="feedback"
                  rules={[{ required: true, message: 'Please input Feedback' }]}
                >
                  <Input.TextArea showCount maxLength={1000} allowClear />
                </Form.Item>
              </Col>

              <Col xs={24} lg={18}>
                <Form.Item style={{ marginTop: '10px' }}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    block
                    style={{ backgroundColor: '#9e1068', border: '0' }}
                    loading={loading}
                  >
                    Send
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </AuthorizationLayout>
  )
}

const styles = {
  inputbox: {
    border: '1px solid gray',
    padding: '4px',
    borderRadius: '5px',
  },
}
const mapStateToProps = (state, ownState) => {
  return {
    auth: state.auth,
    user: state.user,
  }
}

const mapDispatchToProps = { getConsultantInfo, updateProfile }

export default connect(mapStateToProps, mapDispatchToProps)(Feedback)
