import React from 'react'
import {
  Spin,
  Typography,
  Row,
  Col,
  Image,
  Divider,
  Rate,
  Card,
  Avatar,
} from 'antd'
import {
  CheckOutlined,
  MessageOutlined,
  CloseCircleOutlined,
  PhoneOutlined,
  ClockCircleOutlined,
  MedicineBoxOutlined,
  UserOutlined,
} from '@ant-design/icons'
import moment from 'moment'

import fallBackImage from '../../utils/fallBackImage'

const { Title, Text, Link } = Typography

function ConProfileTopRow(props) {
  const { user } = props

  return (
    <Row style={{ margin: 10, padding: 5, boxShadow: '1px 1px 3px #333' }}>
      {/* {singleConsultantLoading && <Spin />} */}
      {user && (
        <>
          <Col xs={20} sm={16} md={12} lg={3} xl={2}>
            <Image
              src={user.picture ? user.picture : fallBackImage()}
              preview={false}
            />
          </Col>
          <Col
            xs={24}
            sm={4}
            md={6}
            lg={10}
            xl={10}
            style={{ paddingLeft: '15px', borderRight: '1px solid gray' }}
          >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Title level={4} style={{ marginBottom: 3 }}>
                {user.name}
              </Title>

              <Text>
                <span style={{}}>{user.specialist}</span>{' '}
              </Text>

              <Link href="https://www.google.com" target="_blank">
                {user.workLoc ? user.workLoc : 'N/A'}
              </Link>
              <Text>{`${user.region}, ${user.country}`} </Text>
            </div>
          </Col>
          <Col
            xs={24}
            sm={4}
            md={6}
            lg={11}
            xl={10}
            style={{ paddingLeft: '20px' }}
          >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Text>
                <CheckOutlined style={{ color: '#0FA958' }} /> Accepting New
                Patients
              </Text>
              <Text>
                <MessageOutlined style={{ color: '#0FA958' }} /> Accepting Chat
                Message
              </Text>

              <Text>
                <ClockCircleOutlined style={{ color: '#0FA958' }} /> Hours:{' '}
                {user.endTime ? (
                  <span style={{ color: '#0FA958' }}>
                    {' '}
                    {moment(user.startTime, 'h:mm').format('hh:mm A')}{' '}
                    <span style={{ fontSize: 16 }}>to </span>
                    {moment(user.endTime, 'h:mm').format('hh:mm A')}
                  </span>
                ) : (
                  <span style={{ color: '#C21E56' }}>Not provided</span>
                )}
              </Text>
              <Text>
                <CloseCircleOutlined style={{ color: '#0FA958' }} /> Closed :{' '}
                {user.offDays?.map((el, idx) => (
                  <span>{el} | </span>
                ))}
              </Text>
            </div>
          </Col>
        </>
      )}
    </Row>
  )
}

export default ConProfileTopRow
