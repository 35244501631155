import React from 'react'
import { Button } from 'antd'
import { Row, Col } from 'antd'
import { ArrowDownOutlined } from '@ant-design/icons'
import mentalhealth from '../../assets/images/mentalhealth.png'

function Apps() {
  return (
    <Row className="app-row" style={{ padding: `2rem` }}>
      <Col xs={24} sm={24} md={12} lg={12}>
        {/* <div id="left"> */}
        <h1>Download our mobile apps</h1>
        <div id="line-left"></div>
        <div style={styles.marginHorizontal} />
        <p>
          Our dedicated patient engagement app and web portal allow you to
          access information instantaneously (no tedeous form, long calls, or
          administrative hassle) and securely
        </p>
        <Button style={{ width: '30%' }}>
          Download <ArrowDownOutlined />
        </Button>
        {/* </div> */}
      </Col>
      <Col xs={24} sm={24} md={12} lg={12}>
        {/* <div id="right"> */}
        <img src={mentalhealth} alt="" style={{ width: '100%' }} />
        {/* </div> */}
      </Col>
    </Row>
  )
}

export default Apps

const styles = {
  marginHorizontal: {
    marginTop: 20,
    marginBottom: 10,
  },
}
