import React, { useEffect, useState } from 'react'
import {
  Typography,
  Table,
  Avatar,
  Collapse,
  Select,
  Slider,
  Input,
  Tag,
} from 'antd'
import { CloseCircleOutlined } from '@ant-design/icons'
import { connect, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { UserOutlined, FilterOutlined } from '@ant-design/icons'
import moment from 'moment'
import { API } from '../api/index.js'
import AuthorizationLayout from '../layout/AuthorizationLayout.jsx'
import { getConsultants } from '../redux/user/user.action'
import { SET_CONSULTANTS } from '../redux/types'

const { Title } = Typography
const { Panel } = Collapse
const { Option } = Select
const { Search } = Input

const columns = [
  {
    title: 'Therapist name',
    key: 'name',
    render: (data) => {
      return (
        <span>
          <Avatar
            size={{ xs: 24, sm: 32, md: 40, lg: 50, xl: 45, xxl: 50 }}
            src={data.picture}
            style={{ border: '1px solid #0FA958' }}
          >
            <UserOutlined />
          </Avatar>
          <span style={{ fontWeight: 'bold', marginLeft: 2 }}>{data.name}</span>
        </span>
      )
    },
  },
  {
    title: 'Specialist',
    dataIndex: 'specialist',
    key: 'specialist',
    render: (data) => {
      if (data) return <span>{data}</span>
      else return <span>N/A</span>
    },
  },
  {
    title: 'Available Time (IST)',
    key: 'endTime',
    render: (data) => {
      if (data.endTime)
        return (
          <span>
            {moment(data.startTime, 'h:mm').format('hh:mm A')}{' '}
            <span style={{ fontSize: 16 }}>to </span>
            {moment(data.endTime, 'h:mm').format('hh:mm A')}
          </span>
        )
      else return <span>N/A</span>
    },
  },
  {
    title: 'Reviews',
    dataIndex: 'review',
    key: 'review',
    render: (data) => {
      if (data) return <span>{data}</span>
      else return <span>N/A</span>
    },
  },
  {
    title: 'View',
    render: (data) => <Link to={`/consultant/${data._id}`}>Profile</Link>,
    key: 'view',
  },
]

// const data = [
//   {
//     name: 'Theprapist User Name',
//     specialties: 'Psychiatrist',
//     timeSlot: '9:00 AM to 2:00 PM',
//     review: '4.9',
//   },
// ]

const Consultants = (props) => {
  const { auth, user, getConsultants } = props
  const { consultants, getConsultantLoading } = user
  const [specialists, Setspecialists] = useState([])
  const [consaltantSearchResult, setconsaltantSearchResult] = useState([])
  const [selectValue, setSelectValue] = useState('')
  const [showSelectTag, setShowSelectTag] = useState(false)
  const dispatch = useDispatch()

  const getSpecialists = async () => {
    const data = await API({
      Authorization: `Bearer ${auth.user?.token}`,
    }).get('/api/v1/user/getAllSpecialistTypes', {})
    const alldata = data.data.specialistTypes
    Setspecialists(alldata)
  }

  useEffect(() => {
    getConsultants()
    getSpecialists() // get all the specialits types
  }, [])

  const handleSearch = async (txt) => {
    if (txt === ' ') return // more comprehensive checks can be added here later on.

    // Store text in search state
    // setSrcTxt(txt)

    // checking if not an empty string
    if (!!txt) {
      // Send search query at backend
      const response = await API({
        Authorization: `Bearer ${auth.user?.token}`,
      }).get(`/api/v1/user/searchUser?keyword=${txt}&consultant=true`, {})

      // store search results, to render as list
      dispatch({ type: SET_CONSULTANTS, payload: response.data.users })
    } else {
      // TODO: remove this reload(), remove data from array properly, and fetch list as initial list of the route
      window.location.reload()
    }
  }

  // start select consaltant functionality
  const selectOnChange = (e) => {
    setSelectValue(e)
    handleConsaltant(e)
  }
  const handleConsaltant = (inputValue) => {
    const filterConsaltantSpecialsist = consultants.filter(
      (consultant) => consultant.specialist === inputValue,
    )
    setconsaltantSearchResult(filterConsaltantSpecialsist)
    if (filterConsaltantSpecialsist.length !== 0) {
      setShowSelectTag(true)
    }
  }
  const selcetedTag = (e) => {}
  const close = () => {
    setShowSelectTag(false)
  }
  return (
    <AuthorizationLayout>
      <div style={{ margin: 10, padding: 5, boxShadow: '1px 1px 3px #333' }}>
        <Title level={5}>Consultants List</Title>
        <Collapse ghost expandIcon={(p) => <FilterOutlined {...p} />}>
          <Panel header="Add filters" key="1">
            <div>
              <Select
                showSearch
                style={{ width: 200 }}
                placeholder="Specialist types"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
                onChange={selectOnChange}
              >
                {specialists.map((v, i) => {
                  return (
                    <Option key={i} value={v}>
                      {v}
                    </Option>
                  )
                })}
              </Select>
              {showSelectTag ? (
                <Tag
                  style={{ cursor: 'pointer' }}
                  onClick={close}
                  onClose={selcetedTag}
                >
                  {selectValue || 'Please Select'}
                  <CloseCircleOutlined
                    style={{ marginLeft: '5px', fontSize: '12px' }}
                  />
                </Tag>
              ) : (
                ''
              )}
            </div>
            <div style={{ display: 'flex' }}>
              <p style={{ margin: 0 }}>Free $/hr </p>
              <div style={{ width: '3px' }}></div>
              <Slider defaultValue={30} style={{ width: 200 }} max={1000} />
            </div>
          </Panel>
        </Collapse>
        <Search
          placeholder="Consultant name"
          allowClear
          enterButton="Search"
          // onSearch={handleSearche}
          size="large"
          onChange={(evt) => handleSearch(evt.target.value)}
        />

        {showSelectTag ? (
          <Table
            loading={getConsultantLoading}
            columns={columns}
            dataSource={consaltantSearchResult} // filtered consultants (don't know it its working or not)
            scroll={{ x: true }}
            // title={() => `Data for:`}
            onChange={onTableChange}
          />
        ) : (
          <Table
            loading={getConsultantLoading}
            columns={columns}
            dataSource={consultants}
            scroll={{ x: true }}
            // title={() => `Data for:`}
            onChange={onTableChange}
          />
        )}
      </div>
    </AuthorizationLayout>
  )
}

const mapStateToProps = (state, ownState) => {
  return {
    auth: state.auth,
    user: state.user,
  }
}

const mapDispatchToProps = {
  getConsultants,
}

export default connect(mapStateToProps, mapDispatchToProps)(Consultants)

function onTableChange(pagination, filters, sorter, extra) {
  console.log('params', pagination, filters, sorter, extra)
}
