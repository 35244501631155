import { API } from '../../api/index'
import {
  SIGNUP_LOADING,
  LOGIN_LOADING,
  SET_USER,
  UPDATE_USER_INFO_NOT_TOKEN,
  SIGNUP_ERROR,
  LOGIN_ERROR,
  LOGOUT,
  CLEAR_USER_DATA,
  FORGOT_PASS_RESPONSE,
} from '../types'

import { message, Button, Space } from 'antd'

const success = () => {
  message.success('Success ')
}

const error = () => {
  message.error('Error')
}

export const signup =
  (obj, setShowSuccessModal) => async (dispatch, getState) => {
    try {
      dispatch({ type: SIGNUP_LOADING, payload: true })
      dispatch({ type: SIGNUP_ERROR, payload: null }) // Clear if any

      console.log(obj)

      // POST signup request
      // TODO: Don't send token from backend to sign in future
      const response = await API().post('/api/v1/auth/signup', {
        ...obj,
        ...obj.user,
      })

      // console.log(response)
      // dispatch({ type: SET_USER, payload: response.data })
      if (setShowSuccessModal) setShowSuccessModal(true)

      dispatch({ type: SIGNUP_LOADING, payload: false })
    } catch (err) {
      // console.log('signup() error')
      dispatch({ type: SIGNUP_LOADING, payload: false })
      if (typeof err.response?.data?.message === 'string') {
        dispatch({ type: SIGNUP_ERROR, payload: err.response?.data?.message })
      } else {
        dispatch({ type: SIGNUP_ERROR, payload: `Please try again later.` })
      }
    }
  }

export const login = (obj, history) => async (dispatch, getState) => {
  try {
    dispatch({ type: LOGIN_LOADING, payload: true })
    dispatch({ type: LOGIN_ERROR, payload: null })

    console.log(obj)

    // POST login request
    const response = await API().post('/api/v1/auth/adminLogin', {
      ...obj,
      ...obj.user,
    })

    console.log(response)
    dispatch({ type: SET_USER, payload: response.data })

    dispatch({ type: LOGIN_LOADING, payload: false })
  } catch (err) {
    console.log('login() error')
    dispatch({ type: LOGIN_LOADING, payload: false })
    if (typeof err.response?.data?.message === 'string') {
      dispatch({ type: LOGIN_ERROR, payload: err.response?.data?.message })
    } else {
      dispatch({ type: LOGIN_ERROR, payload: `Please try again later.` })
    }
  }
}

export const logout = () => {
  return async (dispatch, getState) => {
    try {
      if (process.env.REACT_APP_ENV === 'development')
        console.log('logout() called')
      window.location = `${process.env.PUBLIC_URL}/login`
      dispatch({ type: LOGOUT })
      dispatch({ type: CLEAR_USER_DATA }) // clear user datas

      localStorage.removeItem('userData')
    } catch (err) {
      console.log('logout() error')
      console.log(err)
    }
  }
}

export const submitForgotPass =
  (obj, history) => async (dispatch, getState) => {
    try {
      dispatch({ type: LOGIN_LOADING, payload: true })
      dispatch({ type: LOGIN_ERROR, payload: null })
      dispatch({ type: FORGOT_PASS_RESPONSE, payload: null })

      console.log(obj.user)

      // POST forgot password request
      const response = await API().post('/api/v1/auth/forgotPassword', {
        email: obj.user.email,
      })

      console.log(response.status)
      dispatch({ type: FORGOT_PASS_RESPONSE, payload: response.status })

      dispatch({ type: LOGIN_LOADING, payload: false })
    } catch (err) {
      console.log(err.response)
      console.log('submitForgotPass() error')
      dispatch({ type: LOGIN_LOADING, payload: false })
      console.log(err.response.status)
      console.log(err.response.data)
      if (err.response.status === 404) {
        // dispatch({ type: FORGOT_PASS_RESPONSE, payload: err.response.status }) // Use this value later if needed.
      }
      if (typeof err.response?.data?.message === 'string') {
        dispatch({ type: LOGIN_ERROR, payload: err.response?.data?.message })
      } else {
        dispatch({ type: LOGIN_ERROR, payload: `Please try again later.` })
      }
    }
  }

export const resetPassword = (obj, history) => async (dispatch, getState) => {
  try {
    dispatch({ type: LOGIN_LOADING, payload: true })
    dispatch({ type: LOGIN_ERROR, payload: null })
    dispatch({ type: FORGOT_PASS_RESPONSE, payload: null })

    // POST reset password request
    const response = await API().patch(
      `/api/v1/auth/resetPassword/${obj.token}`,
      {
        password: obj.password,
        passwordConfirm: obj.passwordConfirm,
      },
    )

    console.log(response.data)
    dispatch({ type: SET_USER, payload: response.data })

    dispatch({ type: LOGIN_LOADING, payload: false })
  } catch (err) {
    console.log(err.response)
    console.log('resetPassword() error')
    dispatch({ type: LOGIN_LOADING, payload: false })
    if (err.response.status === 404) {
      // dispatch({ type: FORGOT_PASS_RESPONSE, payload: err.response.status }) // Use this value later if needed.
    } else if (typeof err.response?.data?.message === 'string') {
      dispatch({ type: LOGIN_ERROR, payload: err.response?.data?.message })
    } else {
      dispatch({ type: LOGIN_ERROR, payload: `Please try again later.` })
    }
  }
}

// Currently using both for consultant and normal user profile update
export const updateProfile = (obj) => async (dispatch, getState) => {
  try {
    // console.log(obj)

    // POST update profile
    const response = await API({
      Authorization: `Bearer ${getState().auth.user?.token}`,
    }).post(`/api/v1/auth/updateProfile`, { ...obj })

    console.log(response.data)
    success()
  } catch (err) {
    error()
  }
}
