import { API } from '../api'

export const getUser = async (id, token) => {
  try {
    const response = await API({
      Authorization: `Bearer ${token}`,
    }).get(`/api/v1/user/getConsultantinfo/${id}`, {})

    return response.data.consultant
  } catch (err) {
    console.log(err)
  }
}

export const getNotifications = async (token) => {
  try {
    const response = await API({
      Authorization: `Bearer ${token}`,
    }).get(`/api/v1/user/getUserNotifications`, {})

    return response.data.notifications
  } catch (err) {
    console.log(err)
  }
}

export const readANotification = async (token, notificationId) => {
  try {
    console.log('readANotification')

    const response = await API({
      Authorization: `Bearer ${token}`,
    }).put(
      `/api/v1/user/updateNotification?notificationId=${notificationId}`,
      {},
    )

    return !!response.data
  } catch (err) {
    console.log(err)
  }
}

export const groupMessages = async (obj) => {
  try {
    // GET conversationHistory request
    const response = await API({
      Authorization: `Bearer ${obj.token}`,
    }).get(`/api/v1/user/groupMessages?groupId=${obj.id}`, {})

    return response.data.messages
  } catch (err) {
    console.log(err)
  }
}

export const deleteGroupConvo = async (obj) => {
  try {
    // DELETE conversation request
    const response = await API({
      Authorization: `Bearer ${obj.token}`,
    }).delete(`/api/v1/user/deleteGroupConversation?groupId=${obj.groupId}`, {})
    console.log(response.status)
    return response.status
  } catch (err) {
    console.log(err)
  }
}
