import React, { useState } from 'react'
import {
  Modal,
  Button,
  Radio,
  Space,
  Input,
  List,
  Skeleton,
  Avatar,
} from 'antd'
import { message as messageAntd } from 'antd'
import { useDispatch, connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { MessageOutlined, PlusOutlined } from '@ant-design/icons'
import InfiniteScroll from 'react-infinite-scroll-component'

import { API } from '../../api'
import { createGroupChat } from '../../redux/user/user.action'

const userImg = `https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png`
const { Search } = Input

function CreateGroupModal({ visible, setVisible, createGroupChat, ...props }) {
  const [groupUsers, setGroupUsers] = useState([]) // memebers to add when creating
  const [data, setData] = useState([]) // total users data
  const [loading, setLoading] = useState(false) // loading spinner
  const [totalNormalUsers, setTotalNormalUsers] = useState(0) // counting total data
  const [srcTxt, setSrcTxt] = useState('')
  const [groupName, setGroupName] = useState('')
  const dispatch = useDispatch()

  const handleCancel = () => {
    setVisible(false)
  }

  const onSubmit = (e) => {
    console.log(groupUsers)
    let dup = [...new Set(groupUsers)] // remove duplicate entries
    console.log(dup)
    console.log(createGroupChat)
    // create gropu by redux actionƒ
    dispatch(createGroupChat({ groupName, users: dup, setVisible }))
  }

  const handleSearch = async (txt) => {
    setTotalNormalUsers(0)
    setData([])
    if (txt === ' ') return // more comprehensive checks can be added here later on.

    // Store text in search state
    setSrcTxt(txt)
    console.log(txt)
    // checking if not an empty string
    if (!!txt) {
      console.log(props.auth)
      // Send search query at backend
      const response = await API({
        Authorization: `Bearer ${props.auth.user?.token}`,
      }).get(`/api/v1/user/searchUser?keyword=${txt}&user=true`, {})

      // store remote search results, to render as list
      if (response?.data?.users?.length > 0) {
        console.log(response.data.users)
        setData(response.data.users)
      }
    } else {
      // TODO: remove this reload(), remove data array properly, and fetch list as previous
      // window.location.reload()
    }
  }

  return (
    <Modal
      title="Create a new group"
      visible={visible}
      // onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
    >
      {/* <h1>Create my modal</h1> */}
      <Space direction="vertical" style={{ width: '100%' }}>
        <Input
          placeholder="Group name"
          onChange={(evt) => setGroupName(evt.target.value)}
        />
        <Search
          placeholder="Search users to add"
          allowClear
          // onSearch={handleSearche}
          size="large"
          onChange={(evt) => handleSearch(evt.target.value)}
        />
        {data.length > 0 ? (
          <div
            id="scrollableDiv"
            style={{
              overflow: 'auto',
              padding: '0 16px',
              border: '1px solid rgba(140, 140, 140, 0.35)',
            }}
          >
            {/* <p>Loaded: {JSON.stringify(data.length)}</p>
          <p>Total: {JSON.stringify(totalNormalUsers)}</p> */}
            {loading ? (
              <div className="spiner-center">
                <Skeleton avatar active />
              </div>
            ) : (
              <List
                dataSource={data}
                renderItem={(item) => (
                  <List.Item key={item.id}>
                    <List.Item.Meta
                      avatar={
                        <Avatar
                          src={
                            item.picture === undefined ? userImg : item.picture
                          }
                        />
                      }
                      title={item.name}
                      description={`${item.region ? item.region : ''} ${
                        item.country ? `, ${item.country}` : ''
                      }`}
                    />
                    <div>
                      <PlusOutlined
                        onClick={() => {
                          messageAntd.success('User added', 5)
                          setGroupUsers([...groupUsers, item._id])
                        }}
                      />
                    </div>
                  </List.Item>
                )}
              />
            )}
          </div>
        ) : (
          <>{srcTxt && data.length < 1 && <p>No user found</p>}</>
        )}

        <Button
          onClick={onSubmit}
          type="primary"
          block
          style={{ marginTop: 20 }}
        >
          Create group
        </Button>
      </Space>
    </Modal>
  )
}

const mapStateToProps = (state, ownState) => {
  return {
    auth: state.auth,
  }
}

const mapDispatchToProps = { createGroupChat }

export default connect(mapStateToProps, mapDispatchToProps)(CreateGroupModal)
