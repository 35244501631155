import React, { useState } from 'react'
import { Form, Input, Checkbox, Button } from 'antd'
import { connect } from 'react-redux'
import { signup } from '../redux/auth/auth.action'
import { Row, Col, Typography, Alert, DatePicker, Divider } from 'antd'
import GoogleLogin from 'react-google-login'
import { Navigate } from 'react-router-dom'
import { GoogleOutlined, FacebookOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'

import { MainLayout } from '../layout/MainLayout'
import SignupSuccessModal from '../components/auth/SignupSuccessModal'

const { Title } = Typography

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 24 },
}

const SignUp = (props) => {
  const { signup, auth } = props
  const [loginType, setLoginType] = useState(null)
  const [dob, setDob] = useState(new Date().toISOString())
  const [error, setError] = useState(false)
  const [checkError, setCheckError] = useState(false) // Accept terms condition checkbox error
  const [checked, setChecked] = useState(false) // Terms condition checked
  const [showSuccessModal, setShowSuccessModal] = useState(false)

  const birthdayCal = (date) => {
    var today = new Date()
    var birthDate = new Date(date)
    var age = today.getFullYear() - birthDate.getFullYear()
    var m = today.getMonth() - birthDate.getMonth()
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--
    }
    if (age < 18) {
      setError(true)
    } else {
      setError(false)
    }
    setDob(birthDate.toISOString())
  }
  const onCheckboxChange = () => {
    setChecked(!checked)
    if (checked) {
      setCheckError(true)
    } else {
      setCheckError(false)
    }
  }
  const onFinish = (values) => {
    console.log({ ...values, role: loginType })
    if (checked) {
      if (loginType === 'consultant') {
        signup({ ...values, role: loginType, checked }, setShowSuccessModal)
      } else {
        signup(
          { ...values, role: loginType, dob, checked },
          setShowSuccessModal,
        )
      }
    } else {
      setCheckError(true)
    }
  }

  const responseGoogleSuccess = (res) => {
    // console.log(res)
    let values = { code: res.code }
    signup({ ...values, role: loginType }, setShowSuccessModal)
  }
  const validateMessages = {
    types: {
      email: 'Email is not a valid email!',
    },
  }
  if (props.auth.user) {
    return <Navigate to="/dashboard" />
  }
  return (
    <MainLayout>
      <Row
        style={{
          minHeight: '500px',
          justifyContent: 'center',
          filter: `drop-shadow(2 1 0.15rem grey)`,
          padding: `2rem`,
          marginBottom: '40px',
          marginTop: '20px',
        }}
      >
        <Col xs={{ span: 0 }} md={{ span: 7 }} lg={{ span: 7 }}>
          <div
            style={{
              backgroundColor: '#9e1068',
              borderRadius: 20,
              paddingTop: 20,
              paddingLeft: 20,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              paddingBottom: 20,
              height: '100%',
              marginRight: -25,
            }}
          >
            <div>
              <Title
                level={3}
                style={{
                  color: '#fff',
                }}
              >
                Getting <br /> Started With
              </Title>

              <div
                style={{
                  display: 'flex',
                  fontWeight: 'bold',
                  fontSize: 24,
                  alignItems: 'center',
                }}
              >
                <img
                  style={{ height: '50px' }}
                  src="https://stco.s3.amazonaws.com/all/timestamp-1644153797678.png"
                  alt="stco-logo"
                />
                <img
                  src="https://stco.s3.amazonaws.com/all/timestamp-1644086179888.png"
                  alt=""
                />
              </div>
            </div>
            <div>
              <p style={{ color: '#fff', marginBottom: 0, fontSize: 19 }}>
                You are not alone...
              </p>
              <p style={{ color: '#fff', marginBottom: 0, fontSize: 19 }}>
                We are here to help you.
              </p>
            </div>
          </div>
        </Col>
        <Col
          xs={{ span: 24 }}
          md={{ span: 10 }}
          lg={{ span: 10 }}
          style={{
            backgroundColor: '#fff',
            borderRadius: 20,
            // marginLeft: -30,
            // border: '1px solid green',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              // border: '1px solid black',
              maxWidth: '700px',
              height: '100%',
              width: '100%',
            }}
          >
            {!loginType ? (
              <>
                <h2>Please select an user type</h2>

                <Button
                  type="primary"
                  block
                  style={{
                    width: '80%',
                    backgroundColor: '#9e1068',
                    border: '0',
                  }}
                  onClick={() => {
                    setLoginType('user')
                  }}
                >
                  Normal user
                </Button>
                <div style={{ height: 10 }} />
                <Button
                  type="primary"
                  block
                  style={{
                    width: '80%',
                    backgroundColor: '#9e1068',
                    border: '0',
                  }}
                  onClick={() => setLoginType('consultant')}
                >
                  Consultant user
                </Button>

                <div className="have_an_account" style={{ marginTop: 7 }}>
                  <span>
                    Already have an account?{' '}
                    <Link to="/login" style={{ color: `#9e1068` }}>
                      Login
                    </Link>
                  </span>
                </div>
              </>
            ) : (
              <>
                <h2
                  style={{
                    color: 'black',
                    fontWeight: 700,
                    marginTop: 5,
                  }}
                >
                  Create an account
                </h2>
                <div className="sign_with_google_facebook">
                  <Row wrap gutter={[20, 20]}>
                    <Col xs={10} lg={12}>
                      <GoogleLogin
                        clientId={process.env.REACT_APP_CLIENT_ID}
                        buttonText="Signup with google"
                        onSuccess={responseGoogleSuccess}
                        // onFailure={responseGoogle}
                        accessType="offline"
                        responseType="code"
                        cookiePolicy={'single_host_origin'}
                        render={(renderProps) => (
                          <Button
                            onClick={renderProps.onClick}
                            disabled={renderProps.disabled}
                            // icon={<GoogleOutlined />}
                            style={{ fontSize: `1vmax` }}
                          >
                            <div className="flex justify-center	items-center">
                              <GoogleOutlined /> Signup with google
                            </div>
                          </Button>
                        )}
                      />
                    </Col>
                    <Col xs={10} lg={12}>
                      <div className="facebook">
                        <Button
                          // icon={<FacebookOutlined />}
                          style={{ fontSize: `1vmax` }}
                        >
                          <div className="flex justify-center	items-center">
                            <FacebookOutlined /> Signup with Facebook
                          </div>
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </div>
                <Divider plain> OR </Divider>
                <Form
                  onFinish={onFinish}
                  {...layout}
                  style={{ margin: 20 }}
                  validateMessages={validateMessages}
                >
                  <Row justify="center">
                    <Col xs={20} lg={18}>
                      <Form.Item
                        name={['user', 'name']}
                        rules={[{ required: true }]}
                      >
                        <Input placeholder="Your name" />
                      </Form.Item>
                    </Col>
                    <Col xs={20} lg={18}>
                      <Form.Item
                        name={['user', 'email']}
                        rules={[{ type: 'email', required: true }]}
                        // rules={[{ required: true }]}
                      >
                        <Input placeholder="Email" />
                      </Form.Item>
                    </Col>
                    {loginType === 'consultant' ? (
                      ''
                    ) : (
                      <Col xs={20} lg={18}>
                        <Form.Item>
                          <DatePicker
                            placeholder="Date of Birth"
                            style={{ width: '100%' }}
                            format="YYYY-MM-DD"
                            onChange={birthdayCal}
                          />
                          <span style={{ color: 'red' }}>
                            {error ? 'Your Age Under 18' : ''}
                          </span>
                        </Form.Item>
                      </Col>
                    )}
                    <Col xs={20} lg={18}>
                      <Form.Item
                        name={['user', 'driving']}
                        rules={[{ required: true }]}
                      >
                        <Input placeholder="Driving licence (e.g. 234234)" />
                      </Form.Item>
                    </Col>
                    <Col xs={20} lg={18}>
                      <Form.Item
                        name="password"
                        rules={[
                          {
                            required: true,
                            message: 'Please input your password!',
                          },
                        ]}
                        hasFeedback
                      >
                        <Input.Password placeholder="Password" />
                      </Form.Item>
                    </Col>
                    <Col xs={20} lg={18}>
                      <Form.Item
                        name="confirm"
                        dependencies={['password']}
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: 'Please confirm your password!',
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (
                                !value ||
                                getFieldValue('password') === value
                              ) {
                                return Promise.resolve()
                              }

                              return Promise.reject(
                                new Error(
                                  'The two passwords that you entered do not match!',
                                ),
                              )
                            },
                          }),
                        ]}
                      >
                        <Input.Password placeholder="Confirm password" />
                      </Form.Item>
                    </Col>
                    <Col xs={20} lg={18}>
                      <Form.Item>
                        <Checkbox checked={checked} onChange={onCheckboxChange}>
                          Accept terms and conditions
                        </Checkbox>
                        <span style={{ color: 'red', display: 'block' }}>
                          {checkError
                            ? 'Should accept terms and conditions'
                            : ''}
                        </span>
                      </Form.Item>
                    </Col>
                    <Col xs={20} lg={18}>
                      {auth.signupErr && (
                        <Alert message={auth.signupErr} type="error" />
                      )}

                      <Form.Item style={{ marginBottom: '0px' }}>
                        <Button
                          type="primary"
                          htmlType="submit"
                          block
                          style={{ backgroundColor: '#9e1068', border: '0' }}
                          loading={auth.signupLoading}
                        >
                          Signup
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
                <div className="have_an_account" style={{ marginBottom: 7 }}>
                  <span>
                    Already have an account?{' '}
                    <Link to="/Login" style={{ color: `#9e1068` }}>
                      Login
                    </Link>
                  </span>
                </div>
              </>
            )}
          </div>
        </Col>
      </Row>
      <SignupSuccessModal
        visible={showSuccessModal}
        setVisible={setShowSuccessModal}
      />
    </MainLayout>
  )
}

const mapStateToProps = (state, ownState) => {
  return {
    auth: state.auth,
  }
}

const mapDispatchToProps = {
  signup,
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUp)
