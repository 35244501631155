// test
import React, { useState, useEffect } from 'react'
import { Typography, Col, Row, Button, Card, Divider, Input, Spin } from 'antd'
import { List, message, Avatar } from 'antd'
import VirtualList from 'rc-virtual-list'
import { connect, useDispatch } from 'react-redux'
import { WechatOutlined } from '@ant-design/icons'
import { useNavigate, Link } from 'react-router-dom'
import moment from 'moment'
import { API } from '../api/index'
import QuestionModal from '../components/dashboard/QuestionModal'
import ConProfileTopRow from '../components/common/ConProfileTopRow.js'

import UserDashboardFooter from '../layout/common/UserDashboardFooter'
import AuthorizationLayout from '../layout/AuthorizationLayout.jsx'

import { inboxHistory } from '../redux/user/user.action'

const { Title } = Typography

const fakeDataUrl =
  'https://randomuser.me/api/?results=20&inc=name,gender,email,nat,picture&noinfo'
const ContainerHeight = 275

const Logs = (props) => {
  const { auth, inboxHistory, user } = props
  const [qModal, setQModal] = useState(false) // Modal for questionaire
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)

  const [email, setEmail] = useState('')

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const getData = async () => {
    setLoading(true)
    const response = await API({
      Authorization: `Bearer ${auth.user?.token}`,
    }).get(`/api/v1/user/getAllAdminLogs`)
    console.log(response.data)
    setData(response.data.adminLogs)
    setLoading(false)
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <AuthorizationLayout>
      <Row
        gutter={{ xs: 8, sm: 16, md: 24, lg: 0 }}
        justify="center"
        style={{
          // border: '1px solid blue',
          marginLeft: 0,
          marginTop: 10,
        }}
      >
        <Col
          xs={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 24 }}
          style={{
            margin: 0,
            padding: 0,
            // border: '1px solid red',
          }}
        >
          <div
            style={{
              width: '100%',
              height: '100%',
              backgroundColor: '#fff',
              marginLeft: 0,
              padding: 10,
              minHeight: 300,
              // border: '1px solid grey',
            }}
          >
            {loading && <Spin size="large" />}

            <List
              header={
                <div>
                  <h1>Admin logs</h1>
                </div>
              }
              // footer={<div>Footer</div>}
              bordered
              dataSource={data}
              renderItem={(item) => (
                <List.Item
                  style={{
                    border: '1px solid #c4c4c4',
                    flexDirection: 'column',
                    alignItems: 'start',
                  }}
                >
                  <p style={{ color: '#0096FF' }}>{item?.message}</p>
                  <p>
                    {moment(item.createdAt).format('YYYY-MM-DD hh:mm:ss a')}{' '}
                  </p>
                </List.Item>
              )}
            />
          </div>
        </Col>
      </Row>
    </AuthorizationLayout>
  )
}

const styles = {}

const mapStateToProps = (state, ownState) => {
  return {
    auth: state.auth,
    user: state.user,
  }
}

const mapDispatchToProps = {
  inboxHistory,
}

export default connect(mapStateToProps, mapDispatchToProps)(Logs)
