import React, { useState, useEffect } from 'react'
import {
  Spin,
  Typography,
  Row,
  Col,
  Image,
  Divider,
  Rate,
  Card,
  Avatar,
  Button,
  Tooltip,
} from 'antd'
import { connect, useDispatch } from 'react-redux'
import { useParams, Link } from 'react-router-dom'
import {
  CheckOutlined,
  MessageOutlined,
  PhoneOutlined,
  ClockCircleOutlined,
  MedicineBoxOutlined,
  MailOutlined,
  UserOutlined,
  VerifiedOutlined,
} from '@ant-design/icons'
import moment from 'moment'

import AuthorizationLayout from '../layout/AuthorizationLayout.jsx'

import { getConsultantInfo } from '../redux/user/user.action'

const { Title, Text } = Typography

const UserSingle = (props) => {
  const { auth, user, getConsultantInfo } = props
  const { singleConsultantLoading, tempConsultant } = user
  const [id, setId] = useState(useParams().id)

  const dispatch = useDispatch()

  useEffect(() => {
    getConsultantInfo(id)
  }, [])

  return (
    <AuthorizationLayout>
      <Row
        style={{ margin: 10, padding: 5, boxShadow: '1px 1px 3px #333' }}
        gutter={8}
      >
        {singleConsultantLoading && <Spin />}
        {tempConsultant && (
          <>
            <Col xs={20} sm={16} md={12} lg={3} xl={2}>
              <Image
                src={
                  tempConsultant.picture ? tempConsultant.picture : fallBack()
                }
                preview={false}
              />
            </Col>
            <Col xs={24} sm={4} md={6} lg={10} xl={10}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Title level={4}>
                  {tempConsultant.name}{' '}
                  {tempConsultant.emailVerified && (
                    <Tooltip title="Verified">
                      <VerifiedOutlined style={{ color: '#F9C613 ' }} />
                    </Tooltip>
                  )}
                </Title>
                <Text>
                  {tempConsultant.region}, {tempConsultant.country}
                </Text>
                {tempConsultant.dob && (
                  <Text>
                    Birth:{' '}
                    {moment(tempConsultant.dob).format('dddd, MMMM Do YYYY')}
                  </Text>
                )}
              </div>
            </Col>
            <Col xs={24} sm={4} md={6} lg={11} xl={10}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {tempConsultant.phone && (
                  <a
                    href={`mailto:${tempConsultant.phone}`}
                    target="_blank"
                    style={{ color: '#0FA958' }}
                  >
                    <PhoneOutlined style={{ color: '#0FA958' }} />{' '}
                    {tempConsultant.phone}
                  </a>
                )}
                <a
                  href={`mailto:${tempConsultant.email}`}
                  target="_blank"
                  style={{ color: '#0FA958' }}
                >
                  <MailOutlined style={{ color: '#0FA958' }} />{' '}
                  {tempConsultant.email}
                </a>{' '}
                <Link to={`/inbox/${tempConsultant._id}`}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    // block
                    style={{
                      backgroundColor: '#9e1068',
                      border: '0',
                      width: '50%',
                    }}
                    // loading={auth.loginLoading}
                  >
                    <PhoneOutlined style={{ color: '#fff' }} />
                    <MessageOutlined style={{ color: '#fff' }} />
                  </Button>{' '}
                </Link>
              </div>
            </Col>
          </>
        )}
      </Row>
      <Row style={{ margin: 10, padding: 5, boxShadow: '1px 1px 3px #333' }}>
        {singleConsultantLoading && <Spin />}
        <Title level={5} style={{ marginBottom: 0 }}>
          Overview
        </Title>
        <Divider style={{ margin: 10 }} />
        {tempConsultant && (
          <>
            {/* <Col xs={20} sm={16} md={12} lg={6} xl={6}></Col> */}
            <Col xs={24} sm={4} md={6} lg={18} xl={18}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {tempConsultant.about && (
                  <>
                    <Title level={4}>About</Title>

                    <Text>{tempConsultant.about}</Text>
                  </>
                )}
              </div>
            </Col>
          </>
        )}
      </Row>

      <Row>
        <Col>
          <div style={{ height: 10 }}></div>
        </Col>
      </Row>
    </AuthorizationLayout>
  )
}

const mapStateToProps = (state, ownState) => {
  return {
    auth: state.auth,
    user: state.user,
  }
}

const mapDispatchToProps = { getConsultantInfo }

export default connect(mapStateToProps, mapDispatchToProps)(UserSingle)

function fallBack() {
  return `https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png`
}
