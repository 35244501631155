import React, { useState } from 'react'
import { Form, Input, Radio, Button } from 'antd'
import { connect } from 'react-redux'
import { Row, Col, Typography, Alert, Space, Divider } from 'antd'
import GoogleLogin from 'react-google-login'
import { Navigate } from 'react-router-dom'

import { GoogleOutlined, FacebookOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'

import { login } from '../redux/auth/auth.action'
import { MainLayout } from '../layout/MainLayout'

const { Title } = Typography

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 24 },
}

const Login = (props) => {
  const { login, auth } = props

  const onFinish = (values) => {
    // console.log({ ...values})
    login({ ...values })
  }

  const responseGoogleSuccess = (res) => {
    // console.log(res)
    let values = { code: res.code }
    login({ ...values })
  }
  const validateMessages = {
    required: '${type} is required!',
    types: {
      email: 'Email is not a valid email!',
    },
  }
  if (props.auth.user) {
    return <Navigate to="/dashboard" />
  }

  return (
    <MainLayout>
      <Row
        style={{
          minHeight: '500px',
          justifyContent: 'center',
          filter: `drop-shadow(2 1 0.15rem grey)`,
          padding: `2rem`,
          marginBottom: '40px',
          marginTop: '20px',
        }}
      >
        <Col
          xs={{ span: 24 }}
          md={{ span: 10 }}
          lg={{ span: 10 }}
          style={{
            backgroundColor: '#fff',
            borderRadius: 20,
            // marginLeft: -30,
            // border: '1px solid green',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              // border: '1px solid black',
              maxWidth: '700px',
              height: '100%',
              width: '100%',
            }}
          >
            <h2
              style={{
                color: 'black',
                fontWeight: 700,
                marginTop: 5,
              }}
            >
              Login your account
            </h2>
            <div className="sign_with_google_facebook"></div>

            <Form
              onFinish={onFinish}
              {...layout}
              style={{ margin: 20 }}
              validateMessages={validateMessages}
            >
              <Row justify="center">
                <Col xs={18} lg={18}>
                  <Form.Item
                    name={['user', 'email']}
                    rules={[{ type: 'email', required: true }]}
                  >
                    <Input placeholder="Email" />
                  </Form.Item>
                </Col>
                <Col xs={18} lg={18}>
                  <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your password!',
                      },
                    ]}
                    hasFeedback
                  >
                    <Input.Password placeholder="Password" />
                  </Form.Item>
                </Col>
                <Col xs={18} lg={18}>
                  {auth.loginErr && (
                    <Alert message={auth.loginErr} type="error" />
                  )}

                  <Form.Item style={{ marginBottom: '0px' }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      block
                      style={{ backgroundColor: '#9e1068', border: '0' }}
                      loading={auth.loginLoading}
                    >
                      Login
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>

            <div className="forgot_password" style={{ marginBottom: 7 }}>
              <span>
                <a
                  href="https://shouldertocryon.health/forgotPassword"
                  style={{ color: `#9e1068` }}
                >
                  Forgot password?
                </a>
              </span>
            </div>
            <div className="have_an_account" style={{ marginBottom: 7 }}>
              <span>
                Don't have an account?{' '}
                <a
                  href="https://shouldertocryon.health/signup"
                  style={{ color: `#9e1068` }}
                >
                  Signup
                </a>
              </span>
            </div>
          </div>
        </Col>
      </Row>
    </MainLayout>
  )
}

const mapStateToProps = (state, ownState) => {
  return {
    auth: state.auth,
  }
}

const mapDispatchToProps = {
  login,
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
