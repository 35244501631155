import React, { useState } from 'react'
import { Form, Input, Button } from 'antd'
import { connect } from 'react-redux'
import { submitForgotPass } from '../redux/auth/auth.action'
import { Row, Col, Typography, Alert, Space, Divider } from 'antd'
import { Navigate } from 'react-router-dom'

import { Link } from 'react-router-dom'

import { MainLayout } from '../layout/MainLayout'

const { Title } = Typography

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 24 },
}

const ForgotPassword = (props) => {
  const { submitForgotPass, auth } = props

  const onFinish = (values) => {
    console.log({ ...values })
    submitForgotPass(values)
  }

  if (props.auth.user) {
    return <Navigate to="/dashboard" />
  }

  return (
    <MainLayout>
      <Row
        style={{
          minHeight: '500px',
          justifyContent: 'center',
          filter: `drop-shadow(2 1 0.15rem grey)`,
          padding: `2rem`,
          marginBottom: '40px',
          marginTop: '20px',
        }}
      >
        <Col xs={{ span: 0 }} md={{ span: 7 }} lg={{ span: 7 }}>
          <div
            style={{
              backgroundColor: '#9e1068',
              borderRadius: 20,
              paddingTop: 20,
              paddingLeft: 20,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              paddingBottom: 20,
              height: '100%',
              marginRight: -25,
            }}
          >
            <div>
              <Title
                level={3}
                style={{
                  color: '#fff',
                }}
              >
                Getting <br /> Started With
              </Title>
              <div
                style={{
                  display: 'flex',
                  fontWeight: 'bold',
                  fontSize: 24,
                  alignItems: 'center',
                }}
              >
                <img
                  style={{ height: '50px' }}
                  src="https://stco.s3.amazonaws.com/all/timestamp-1644153797678.png"
                  alt="stco-logo"
                />
                <img
                  src="https://stco.s3.amazonaws.com/all/timestamp-1644086179888.png"
                  alt=""
                />
              </div>
            </div>
            <div>
              <p style={{ color: '#fff', marginBottom: 0, fontSize: 19 }}>
                You are not alone...
              </p>
              <p style={{ color: '#fff', marginBottom: 0, fontSize: 19 }}>
                We are here to help you.
              </p>
            </div>
          </div>
        </Col>
        <Col
          xs={{ span: 24 }}
          md={{ span: 10 }}
          lg={{ span: 10 }}
          style={{
            backgroundColor: '#fff',
            borderRadius: 20,
            // marginLeft: -30,
            // border: '1px solid green',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              // border: '1px solid black',
              maxWidth: '700px',
              height: '100%',
              width: '100%',
            }}
          >
            {!auth.forgotPassResponse ? (
              <>
                <h2
                  style={{
                    color: 'black',
                    fontWeight: 700,
                    marginTop: 5,
                  }}
                >
                  Forgot Password
                </h2>
                <Form onFinish={onFinish} {...layout} style={{ margin: 20 }}>
                  <Form.Item
                    name={['user', 'email']}
                    rules={[{ type: 'email' }]}
                    rules={[{ required: true }]}
                  >
                    <Input placeholder="Email" />
                  </Form.Item>

                  {auth.loginErr && (
                    <Alert message={auth.loginErr} type="error" />
                  )}

                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      block
                      style={{ backgroundColor: '#9e1068', border: '0' }}
                      loading={auth.loginLoading}
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </Form>
              </>
            ) : (
              <>
                <h2
                  style={{
                    color: 'black',
                    fontWeight: 700,
                    marginTop: 5,
                  }}
                >
                  Check you mail inbox to reset the password
                </h2>
              </>
            )}

            <div className="have_an_account" style={{ marginBottom: 7 }}>
              <span>
                Or you can try to remember and{' '}
                <Link to="/login" style={{ color: `#9e1068` }}>
                  Login
                </Link>
              </span>
            </div>
          </div>
        </Col>
      </Row>
    </MainLayout>
  )
}

const mapStateToProps = (state, ownState) => {
  return {
    auth: state.auth,
  }
}

const mapDispatchToProps = {
  submitForgotPass,
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)
