// test
import React, { useState, useEffect } from 'react'
import { Typography, Col, Row, Button, Card, Divider, Input, Spin } from 'antd'
import { List, message, Avatar } from 'antd'
import VirtualList from 'rc-virtual-list'
import { connect, useDispatch } from 'react-redux'
import { WechatOutlined } from '@ant-design/icons'
import { useNavigate, Link } from 'react-router-dom'
import moment from 'moment'
import { API } from '../api/index'
import QuestionModal from '../components/dashboard/QuestionModal'
import ConProfileTopRow from '../components/common/ConProfileTopRow.js'

import UserDashboardFooter from '../layout/common/UserDashboardFooter'
import AuthorizationLayout from '../layout/AuthorizationLayout.jsx'

import { inboxHistory } from '../redux/user/user.action'

const { Title } = Typography

const fakeDataUrl =
  'https://randomuser.me/api/?results=20&inc=name,gender,email,nat,picture&noinfo'
const ContainerHeight = 275

const ManageUsers = (props) => {
  const { auth, inboxHistory, user } = props
  const [qModal, setQModal] = useState(false) // Modal for questionaire
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)

  const [email, setEmail] = useState('')

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const getData = async () => {
    setLoading(true)
    const response = await API({
      Authorization: `Bearer ${auth.user?.token}`,
    }).get(`/api/v1/user/getAllowedEmails`)
    console.log(response.data)
    setData(response.data.allowedEmails)
    setLoading(false)
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <AuthorizationLayout>
      <Row
        gutter={{ xs: 8, sm: 16, md: 24, lg: 0 }}
        justify="center"
        style={{
          // border: '1px solid blue',
          marginLeft: 0,
          marginTop: 10,
        }}
      >
        <Col
          xs={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 24 }}
          style={{
            margin: 0,
            padding: 0,
            // border: '1px solid red',
          }}
        >
          <div
            style={{
              width: '100%',
              height: '100%',
              backgroundColor: '#fff',
              marginLeft: 0,
              padding: 10,
              minHeight: 300,
              // border: '1px solid grey',
            }}
          >
            {loading && <Spin size="large" />}
            <Input.Group compact>
              <Input
                placeholder="Add a new email to list"
                style={{ width: 'calc(100% - 200px)', marginBottom: 20 }}
                // defaultValue="https://ant.design"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />

              <Button
                type="primary"
                onClick={async () => {
                  setLoading(true)
                  const response = await API({
                    Authorization: `Bearer ${auth.user?.token}`,
                  }).post(`/api/v1/user/addEmail`, {
                    email,
                    addedBy: auth.user?.user.email,
                  })
                  getData()
                  setLoading(false)
                }}
              >
                Submit
              </Button>
            </Input.Group>

            <List
              header={
                <div>
                  <h1>Allowed Emails</h1>
                </div>
              }
              // footer={<div>Footer</div>}
              bordered
              dataSource={data}
              renderItem={(item) => (
                <List.Item
                  style={{
                    border: '1px solid #c4c4c4',
                    flexDirection: 'column',
                    alignItems: 'start',
                  }}
                >
                  <p style={{ color: '#0096FF' }}>{item?.email}</p>
                  <p>Added by: {item?.addedBy} </p>
                  <p>
                    {moment(item.createdAt).format('YYYY-MM-DD hh:mm:ss a')}{' '}
                  </p>
                  <Button
                    danger
                    onClick={async () => {
                      setLoading(true)
                      const response = await API({
                        Authorization: `Bearer ${auth.user?.token}`,
                      }).delete(
                        `/api/v1/user/deleteAllowedEmail?_id=${item._id}&email=${item.email}`,
                      )
                      getData()
                      setLoading(false)
                    }}
                  >
                    Delete
                  </Button>
                </List.Item>
              )}
            />
          </div>
        </Col>
      </Row>
    </AuthorizationLayout>
  )
}

const styles = {}

const mapStateToProps = (state, ownState) => {
  return {
    auth: state.auth,
    user: state.user,
  }
}

const mapDispatchToProps = {
  inboxHistory,
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageUsers)
