import React, { useState } from 'react'
import { Form, Input, Radio, Button } from 'antd'
import { connect } from 'react-redux'
import { resetPassword } from '../redux/auth/auth.action'
import { Row, Col, Typography, Alert, Space, Divider } from 'antd'
import GoogleLogin from 'react-google-login'
import { Navigate, useParams } from 'react-router-dom'

import { GoogleOutlined, FacebookOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'

import { MainLayout } from '../layout/MainLayout'

const { Title } = Typography

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 24 },
}

const ResetPassword = (props) => {
  const { auth, resetPassword } = props

  const [loginType, setLoginType] = useState(null)
  const [token, setToken] = useState(useParams().token) // reset password token from mail inbox

  const onResetFinish = (values) => {
    // console.log(values)
    // console.log(token)
    resetPassword({ ...values, token })
  }

  if (props.auth.user) {
    return <Navigate to="/dashboard" />
  }
  return (
    <MainLayout>
      <Row
        style={{
          minHeight: '500px',
          justifyContent: 'center',
          filter: `drop-shadow(2 1 0.15rem grey)`,
          padding: `2rem`,
        }}
      >
        <Col xs={{ span: 0 }} md={{ span: 10 }} lg={{ span: 10 }}>
          <div
            style={{
              backgroundColor: '#9e1068',
              borderRadius: 20,
              paddingTop: 20,
              paddingLeft: 20,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              paddingBottom: 20,
              height: '100%',
              marginRight: -25,
            }}
          >
            <div>
              <Title
                level={3}
                style={{
                  color: '#fff',
                }}
              >
                Getting <br /> Started With
              </Title>

              <p
                style={{
                  display: 'flex',
                  fontWeight: 'bold',
                  fontSize: 24,
                  alignItems: 'center',
                }}
              >
                <span
                  style={{
                    display: 'flex',
                    background: '#fff',
                    borderRadius: '50%',
                    padding: 5,
                    color: '#9e1068',
                    width: 30,
                    height: 30,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  S
                </span>
                <span style={{ color: '#fff' }}>TCO</span>
              </p>
            </div>
            <div>
              <p style={{ color: '#fff', marginBottom: 0, fontSize: 19 }}>
                You are not alone...
              </p>
              <p style={{ color: '#fff', marginBottom: 0, fontSize: 19 }}>
                We are here to help you.
              </p>
            </div>
          </div>
        </Col>
        <Col
          xs={{ span: 24 }}
          md={{ span: 14 }}
          lg={{ span: 14 }}
          style={{
            backgroundColor: '#fff',
            borderRadius: 20,
            // marginLeft: -30,
            // border: '1px solid green',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <h2
            style={{
              color: 'black',
              fontWeight: 700,
              marginTop: 5,
            }}
          >
            Reset Password
          </h2>
          <Form onFinish={onResetFinish} {...layout} style={{ margin: 20 }}>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Please input your password!',
                },
              ]}
              hasFeedback
            >
              <Input.Password placeholder="Password" />
            </Form.Item>

            <Form.Item
              name="passwordConfirm"
              dependencies={['password']}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: 'Please confirm your password!',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve()
                    }

                    return Promise.reject(
                      new Error(
                        'The two passwords that you entered do not match!',
                      ),
                    )
                  },
                }),
              ]}
            >
              <Input.Password placeholder="Confirm password" />
            </Form.Item>

            {auth.loginErr && <Alert message={auth.loginErr} type="error" />}

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                block
                style={{ backgroundColor: '#9e1068', border: '0' }}
                loading={auth.loginLoading}
              >
                Reset Password
              </Button>
            </Form.Item>
          </Form>
          <div className="have_an_account" style={{ marginBottom: 7 }}>
            <span>
              Token expired?
              <Link to="/forgotPassword" style={{ color: `#9e1068` }}>
                Try again
              </Link>
            </span>
          </div>
        </Col>
      </Row>
    </MainLayout>
  )
}

const mapStateToProps = (state, ownState) => {
  return {
    auth: state.auth,
  }
}

const mapDispatchToProps = {
  resetPassword,
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword)
