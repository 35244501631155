import React, { useState, useEffect } from 'react'
import {
  Form,
  Input,
  Button,
  Col,
  Row,
  TimePicker,
  DatePicker,
  Typography,
  Select,
  Tooltip,
  Upload,
  List,
  Skeleton,
  Avatar,
  Spin,
} from 'antd'
import { connect, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  MinusCircleOutlined,
  PlusOutlined,
  DollarOutlined,
  InfoCircleOutlined,
  VerifiedOutlined,
} from '@ant-design/icons'
import axios from 'axios'
import moment from 'moment'

import { API } from '../api'
import AuthorizationLayout from '../layout/AuthorizationLayout.jsx'

import { getConsultantInfo } from '../redux/user/user.action'
import { updateProfile } from '../redux/auth/auth.action'

const userImg = `https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png`
const { Paragraph } = Typography

const ViewFeedback = (props) => {
  const { auth, user, getConsultantInfo, updateProfile } = props
  const { tempConsultant } = user

  const dispatch = useDispatch()
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)

  const getFeedbacks = async () => {
    setLoading(true)

    try {
      const response = await API({
        Authorization: `Bearer ${auth.user.token}`,
      }).get(`/api/v1/user/feedback`, {})

      setData(response.data.feedbacks)

      console.log(response.data)
      setLoading(false)
    } catch (err) {
      setLoading(false)
      console.log(err)
    }
  }

  useEffect(() => {
    getFeedbacks()
  }, [])

  return (
    <AuthorizationLayout>
      <Row justify="center">
        <Col span={24}>
          <div style={{ padding: 10 }}>
            <h1>List of feedbacks</h1>

            {data.length > 0 ? (
              <div
                id="scrollableDiv"
                style={{
                  overflow: 'auto',
                  padding: '0 16px',
                  border: '1px solid rgba(140, 140, 140, 0.35)',
                }}
              >
                {loading ? (
                  <div className="spiner-center">
                    <Skeleton avatar active />
                  </div>
                ) : (
                  <List
                    dataSource={data}
                    renderItem={(item) => (
                      <List.Item key={item.id}>
                        <List.Item.Meta
                          avatar={
                            <Avatar
                              src={
                                item.picture === undefined
                                  ? userImg
                                  : item.picture
                              }
                            />
                          }
                          title={
                            <Link to={`/user/${item.user[0]?._id}`}>
                              {item.user[0]?.name}
                            </Link>
                          }
                          description={`${
                            item.feedback ? `${item.feedback}` : ''
                          }`}
                        />
                        <div>
                          <p>{item.email}</p>
                          <p>{moment(item.createdAt).fromNow()}</p>
                        </div>
                      </List.Item>
                    )}
                  />
                )}
              </div>
            ) : (
              <>
                {loading && <Spin />}
                {data.length === 0 && !loading && <p>No feeddback yet.</p>}
              </>
            )}
          </div>
        </Col>
      </Row>
    </AuthorizationLayout>
  )
}

const styles = {
  inputbox: {
    border: '1px solid gray',
    padding: '4px',
    borderRadius: '5px',
  },
}
const mapStateToProps = (state, ownState) => {
  return {
    auth: state.auth,
    user: state.user,
  }
}

const mapDispatchToProps = { getConsultantInfo, updateProfile }

export default connect(mapStateToProps, mapDispatchToProps)(ViewFeedback)
