import React from 'react'
import { connect } from 'react-redux'
import { Route, Navigate } from 'react-router-dom'

const PrivateRoute = ({
  children,
  auth: { user }, // 'user' from redux value
  ...rest
}) => {
  //   console.log(children)
  //   console.log(user)
  return user ? children : <Navigate to="/login" />
}

const mapStateToProps = (state) => ({
  auth: state.auth,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute)
