import React from 'react'
import { Routes, Route } from 'react-router-dom'
import HomePage from '../pages/HomePage'
import SignUp from '../pages/SignUp'
import ForgotPassword from '../pages/ForgotPassword'
import ResetPassword from '../pages/ResetPassword' // route from forgot email request
import VerifyEmail from '../pages/VerifyEmail' // route from verify email
import Login from '../pages/Login.jsx'
import Dashboard from '../pages/Dashboard.jsx'
import ManageUsers from '../pages/ManageUsers.jsx'
import Logs from '../pages/Logs.jsx'
import Consultants from '../pages/Consultants.jsx'
import ConsultantSingle from '../pages/ConsultantSingle.jsx'
import Inbox from '../pages/Inbox.jsx'
import GroupInbox from '../pages/GroupInbox.jsx'
import Users from '../pages/Users.jsx'
import UserSingle from '../pages/UserSingle.jsx'
import NotFound from '../pages/NotFound.jsx'
import UserProfile from '../pages/UserProfile'
import PrivateRoute from './PrivateRoute'
import Call from '../pages/Call'
import VideioCall from '../pages/VideioCall'
import Feedback from '../pages/Feedback'
import ViewFeedback from '../pages/ViewFeedback'

const R = ({ ...props }) => {
  return (
    <Routes>
      <Route exact path="/" element={<HomePage />} />

      <Route exact path="/login" element={<Login />} />

      <Route
        path="/dashboard"
        element={
          <PrivateRoute>
            <Users />
          </PrivateRoute>
        }
      />

      <Route
        path="/manageusers"
        element={
          <PrivateRoute>
            <ManageUsers />
          </PrivateRoute>
        }
      />

      <Route
        path="/logs"
        element={
          <PrivateRoute>
            <Logs />
          </PrivateRoute>
        }
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}

export default R
