import { Button, notification, Space } from 'antd'
import { API } from '../../api/index'
import {
  GET_CONSULTANTS_LOADING,
  SET_CONSULTANTS,
  GET_CONSULTANT_LOADING,
  SET_CONSULTANT,
  GET_CONVERSATION_LOADING,
  SET_CONVERSATION,
  SET_INBOX,
  SET_TOTAL_UNREAD_MESSAGES,
  UPDATE_USER_INFO_NOT_TOKEN,
  SET_GROUP_CHAT_LISTS,
} from '../types'

import { getUser } from '../../utils/requests'

export const getConsultants = (obj, history) => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_CONSULTANTS_LOADING, payload: true })

    // GET getConsultants request
    const response = await API({
      Authorization: `Bearer ${getState().auth.user?.token}`,
    }).get('/api/v1/user/getConsultants', {})

    dispatch({ type: SET_CONSULTANTS, payload: response.data.consultants })

    dispatch({ type: GET_CONSULTANTS_LOADING, payload: false })
  } catch (err) {
    // console.log('getConsultants() error')
    dispatch({ type: GET_CONSULTANTS_LOADING, payload: false })
    if (typeof err.response?.data?.message === 'string') {
      // dispatch({ type: SIGNUP_ERROR, payload: err.response?.data?.message })
      console.log(err.response)
    } else {
      // dispatch({ type: SIGNUP_ERROR, payload: `Please try again later.` })
    }
  }
}

// Currently using both for consultant and normal user
export const inboxHistory = () => async (dispatch, getState) => {
  try {
    // GET inboxHistory request
    const response = await API({
      Authorization: `Bearer ${getState().auth.user?.token}`,
    }).get(`/api/v1/user/inboxHistory?`, {})

    // console.log(response.data)

    if (response.data.inbox.length === 0)
      return dispatch({ type: SET_INBOX, payload: [] })

    // Getting opposite persons profile, to show name and image
    let resultArray = await Promise.all(
      response.data.inbox.map(async (el) => {
        // console.log(getState().auth.user?.user?._id)
        if (getState().auth.user?.user?._id === el.from) {
          // console.log(getUser(el.from, getState().auth.user?.token))
          return getUser(el.to, getState().auth.user?.token)
        } else {
          return getUser(el.from, getState().auth.user?.token)
        }
      }),
    )

    // Temporary fix, if a user account is deleted, it will not be shown in inbox
    // TODO: Add some kind of flag at backend to show if user is deleted or not, so that at inbox we can show deleted users conversations, rather than hiding the conversation.
    resultArray = resultArray.filter((user) => user !== null)

    // create final array to render on jsx, with the info and profile array
    const final = resultArray.map((el) => {
      // TODO: FIX THIS, /inbox history is not working (but it was working)
      // console.log(resultArray)
      // Getting the accurate object where to put the image and name
      const data = response.data.inbox.filter((item) =>
        item.uniqueConversationID.includes(el._id),
      )
      // combining and returning after adding name and picture
      return {
        ...data[0],
        oppositeUser: el._id,
        role: el.role,
        name: el.name,
        picture: el.picture,
      }
    })

    dispatch({ type: SET_INBOX, payload: final })
  } catch (err) {
    console.log(err)
    console.log('inboxHistory() error')
  }
}

// Currently using both for consultant and normal user
export const conversationHistory = (from, to) => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_CONVERSATION_LOADING, payload: true })
    // GET conversationHistory request
    const response = await API({
      Authorization: `Bearer ${getState().auth.user?.token}`,
    }).get(`/api/v1/user/conversationHistory?from=${from}&to=${to}`, {})

    // console.log(response.data)

    dispatch({ type: SET_CONVERSATION, payload: response.data.messages })
    dispatch({ type: GET_CONVERSATION_LOADING, payload: false })
  } catch (err) {
    console.log('getSingleConsultant() error')
    dispatch({ type: GET_CONVERSATION_LOADING, payload: false })
  }
}

// Currently using both for consultant and normal user
export const calcTotalUnreadConv = () => async (dispatch, getState) => {
  try {
    // GET calcTotalUnreadConv request
    const response = await API({
      Authorization: `Bearer ${getState().auth.user?.token}`,
    }).get(`/api/v1/user/calcTotalUnreadConv`, {})

    dispatch({
      type: SET_TOTAL_UNREAD_MESSAGES,
      payload: response.data.totalUnreads,
    })
  } catch (err) {
    console.log('calcTotalUnreadConv() error')
  }
}

// Currently using both for consultant and normal user
export const makeAConversationSeen = (from) => async (dispatch, getState) => {
  try {
    // POST makeAConversationSeen request
    const response = await API({
      Authorization: `Bearer ${getState().auth.user?.token}`,
    }).post(`/api/v1/user/makeAConversationSeen`, {
      from,
      to: getState().auth.user.user._id,
    })

    dispatch(calcTotalUnreadConv()) // Mainly to update the badge
  } catch (err) {
    console.log('makeAConversationSeen() error')
  }
}

// Currently using both for consultant and normal user
export const deleteConversation =
  (of, navigate, setMessages) => async (dispatch, getState) => {
    try {
      // DELETE conversation request
      const response = await API({
        Authorization: `Bearer ${getState().auth.user?.token}`,
      }).delete(`/api/v1/user/deleteConversation?of=${of}`, {})

      // Navigate to inbox (without id) & remove the states of messages and udpate redux list of conversations
      navigate('/inbox')
      setMessages([]) // remove single conversation history with the user

      dispatch({
        type: SET_INBOX,
        payload: getState().user.tempInbox.filter(
          (el) => !el.uniqueConversationID.includes(of),
        ),
      }) // Remvoing from conversations list

      // show success message
      openNotificationWithIcon(
        'success',
        'Deleted successfully!',
        'Once deleted, its not possible to undone.',
      )

      console.log(response.status)
    } catch (err) {
      console.log('getSingleConsultant() error')
    }
  }

// Currently using both for consultant and normal user
export const getConsultantInfo = (id) => async (dispatch, getState) => {
  try {
    // console.log('object')
    dispatch({ type: GET_CONSULTANT_LOADING, payload: true })

    // GET getConsultantinfo request
    const response = await API({
      Authorization: `Bearer ${getState().auth.user?.token}`,
    }).get(`/api/v1/user/getConsultantinfo/${id}`, {})

    // console.log(response.data.consultant)

    dispatch({ type: SET_CONSULTANT, payload: response.data.consultant })

    dispatch({ type: GET_CONSULTANT_LOADING, payload: false })
  } catch (err) {
    // console.log('getSingleConsultant() error')
    dispatch({ type: GET_CONSULTANT_LOADING, payload: false })
    if (typeof err.response?.data?.message === 'string') {
      // dispatch({ type: SIGNUP_ERROR, payload: err.response?.data?.message })
      console.log(err.response)
    } else {
      // dispatch({ type: SIGNUP_ERROR, payload: `Please try again later.` })
    }
  }
}

const openNotificationWithIcon = (type, message, description) => {
  notification[type]({
    message,
    description,
  })
}

// for normal users only
export const addTherapist = () => async (dispatch, getState) => {
  try {
    // TODO: add loading spinner

    // PUT
    const response = await API({
      Authorization: `Bearer ${getState().auth.user?.token}`,
    }).put(`/api/v1/user/addTherapist`, {})

    console.log(response.data)

    if (response.data.intentionalError) {
      openNotificationWithIcon(
        'error',
        response.data.message,
        response.data.description,
      )
    }
    dispatch({ type: UPDATE_USER_INFO_NOT_TOKEN, payload: response.data.user })
    setTimeout(() => {
      window.location = `${process.env.PUBLIC_URL}/inbox/${response.data.myTherapist}`
    }, 1000)
  } catch (err) {
    console.log(err)
    console.log('addTherapist() error')
  }
}

// for both type of users
export const startZoom = (socket, id) => async (dispatch, getState) => {
  try {
    openNotificationWithIcon(
      'success',
      'Please wait...',
      'We are redirecting you to the meeting room.',
    )

    // POST
    const response = await API({
      Authorization: `Bearer ${getState().auth.user?.token}`,
    }).post(`/api/v1/user/startZoom`, {})

    if (response.data?.response?.start_url) {
      console.log(response.data.response.start_url)
    }

    // ! Not adding for now because of,large single word style issue at inbox messages
    // socket?.emit(
    //   'sendIndividualMessage',
    //   {
    //     from: getState().auth.user.user._id,
    //     to: id,
    //     message: response.data.response.start_url,
    //   },
    //   () => console.log(''),
    // )

    // This emit is only to open a tab directly on both users, this is message will not be shown a conversation
    //note: backend is also configured to not store this message
    socket?.emit(
      'sendIndividualMessageV2',
      {
        from: getState().auth.user.user._id,
        to: id,
        message: response.data.response.start_url,
      },
      () => console.log(''),
    )
  } catch (err) {
    console.log('startZoom() error')
    console.log(err)
    console.log(err.response?.data?.message)
    openNotificationWithIcon(
      'error',
      'Failed to start the call',
      err.response?.data?.message,
    )
  }
}

// for both type of users
export const createGroupChat = (obj) => async (dispatch, getState) => {
  try {
    console.log('createGroupChat() called')
    openNotificationWithIcon(
      'success',
      'Please wait...',
      'We are creating the group and notifying the user(s) via email.',
    )

    // POST
    const response = await API({
      Authorization: `Bearer ${getState().auth.user?.token}`,
    }).post(`/api/v1/user/createGroupChat`, {
      groupName: obj.groupName,
      users: obj.users,
    })

    // Fetch all the groups again here to update the group list on UI
    const res2 = await API({
      Authorization: `Bearer ${getState().auth.user?.token}`,
    }).get(`/api/v1/user/groupChatLists`, {})

    dispatch({
      type: SET_GROUP_CHAT_LISTS,
      payload: res2.data.groupChatLists,
    })

    if (response.data) {
      console.log(response.data)
      obj.setVisible(false) // Close create group modal
    }
  } catch (err) {
    console.log('createGroupChat() error')
    console.log(err)
    console.log(err.response?.data?.message)
    openNotificationWithIcon(
      'error',
      'Failed to create the group',
      err.response?.data?.message,
    )
  }
}

// for both type of users
export const groupChatLists = (obj) => async (dispatch, getState) => {
  try {
    // console.log('groupChatLists() called')

    // POST
    const response = await API({
      Authorization: `Bearer ${getState().auth.user?.token}`,
    }).get(`/api/v1/user/groupChatLists`, {})

    if (response.data) {
      // console.log(response.data)
      dispatch({
        type: SET_GROUP_CHAT_LISTS,
        payload: response.data.groupChatLists,
      })
    }
  } catch (err) {
    console.log('groupChatLists() error')
    console.log(err)
    console.log(err.response?.data?.message)
    openNotificationWithIcon(
      'error',
      'Failed to get group chat lists',
      err.response?.data?.message,
    )
  }
}

// Gettings a group messages
// * Get it from requests.js
