import { Layout, Menu } from 'antd'
import { useNavigate } from 'react-router-dom'
import logo from '../../assets/images/logo.png'
import { MenuOutlined } from '@ant-design/icons'
import { Row, Col } from 'antd'
const { Header } = Layout
export const NavBar = () => {
  const navigate = useNavigate()

  return (
    <Header
      style={{
        // border: '1px solid blue',
        marginTop: '1.2rem',
        padding: 0,
        paddingLeft: '2rem',
      }}
    >
      <Row>
        <Col
          xl={6}
          lg={6}
          md={6}
          sm={18}
          xs={18}
          className="logo"
          onClick={() => navigate('/')}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
              // height: '90%',
            }}
          >
            <img
              style={{ width: '77px', height: '100%' }}
              src="https://stco.s3.amazonaws.com/all/timestamp-1644153797678.png"
              alt=""
              id="Nav-Logo"
            />
            <img
              style={{ width: '150px', height: '100%', marginLeft: '20px' }}
              src="https://stco.s3.amazonaws.com/all/timestamp-1644086179888.png"
              alt="ban-logo"
            />
          </div>
        </Col>
        <Col
          xl={18}
          lg={18}
          md={18}
          sm={6}
          xs={6}
          // onClick={() => navigate('/')}
          style={{ height: '100%' }}
        >
          <Menu
            mode="horizontal"
            defaultSelectedKeys={['1']}
            className="theme-content"
            overflowedIndicator={
              <MenuOutlined style={{ color: 'rgb(54, 117, 64)' }} />
            }
          >
            {/* <Menu.Item key="1" onClick={() => navigate('/')}>
              Home
            </Menu.Item>
            <Menu.Item key="2">Find a therapist</Menu.Item>
            <Menu.Item key="3">Therapist Job</Menu.Item>
            <Menu.Item key="4">About us</Menu.Item>
            <Menu.Item key="5">Testimonials</Menu.Item>
            <Menu.Item key="6">Contact us</Menu.Item> */}
            {/* <Menu.Item key="7" onClick={() => navigate('/login')}>
              Login
            </Menu.Item> */}
          </Menu>
        </Col>
      </Row>
    </Header>
  )
}
