import React, { useEffect, useState } from 'react'
import {
  Layout,
  Menu,
  Avatar,
  Row,
  Col,
  Typography,
  Badge,
  Button,
  Dropdown,
} from 'antd'
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  PieChartOutlined,
  WechatOutlined,
  QqOutlined,
  MessageOutlined,
  UserOutlined,
  FileSearchOutlined,
  BellOutlined,
  ProfileOutlined,
  MailOutlined,
  DownOutlined,
  LogoutOutlined,
  SearchOutlined,
} from '@ant-design/icons'
import { Link, useLocation } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { logout } from '../redux/auth/auth.action'
import { joinSocket } from '../redux/socket/socket.action'
import { calcTotalUnreadConv } from '../redux/user/user.action'
import RenderNotifications from '../components/authorizedLayout/RenderNotifications'
import { getNotifications } from '../utils/requests'

const userImg = `https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png`
const { Content, Header, Sider } = Layout
const { Text, Paragraph, Title } = Typography
const initialSize = {
  width: window.innerWidth,
}
const allowedUsers = [
  '6285d46a90c2046764b25cc2', // local kml
  '625c4c2dab2f53f6b3fac8c6', // remote
  '621ca5734eb3796fc54e1314', // remote kml
]

const AuthorizationLayout = (props) => {
  const { auth, socket, user, joinSocket, calcTotalUnreadConv } = props

  const [screen, setScreen] = useState(initialSize.width)

  const parseJwt = () => {
    try {
      const encoded = JSON.parse(atob(auth.user.token.split('.')[1]))
      return encoded
    } catch (e) {
      return console.log(e)
    }
  }
  useEffect(() => {
    try {
      const user = JSON.parse(JSON.stringify(auth.user))
      // console.log('user', user)
      if (user) {
        const decoded = parseJwt()
        const decodedExpire = decoded.exp
        // console.log(decodedExpire)
        if (!user) {
          return
        } else if (decodedExpire * 1000 < Date.now()) {
          return dispatch(logout())
        }
      }
    } catch (error) {
      return console.log(error)
    }
  }, [])

  useEffect(() => {
    if (!socket) {
      joinSocket() // not ideal to join socket more than once, if already connected
    }
    calcTotalUnreadConv() // to show badge on sidebar mainly
  }, [socket])

  const [collapsed, setCollapsed] = useState(() => {
    const showCollapsed = localStorage.getItem('collapsed')
    return JSON.parse(showCollapsed) ?? false
  })
  const [currentkey, setCurrentkey] = useState(null)
  const [unreadNotification, setUnreadNotification] = useState(0)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  const menu = (
    <Menu style={{ padding: '5px' }}>
      <Paragraph style={{ margin: 0 }} className="user-top-info">
        Account Type: {auth.user?.user?.role === 'user' ? `User` : `Consultant`}
      </Paragraph>
      <Paragraph style={{ margin: 0 }} className="user-top-info">
        {auth.user?.user?.email}
      </Paragraph>
      <Paragraph style={{ margin: 0 }} strong className="user-top-info">
        {auth.user?.user?.name}
      </Paragraph>
      <Menu.Item
        key="23"
        onClick={() => dispatch(logout())}
        icon={<LogoutOutlined style={{ fontSize: '16px' }} />}
      >
        Logout
      </Menu.Item>
    </Menu>
  )

  const notificationsMenu = <RenderNotifications token={auth.user.token} />

  const toggle = () => {
    setCollapsed(!collapsed)
  }
  const handleClick = (e) => {
    setCurrentkey(e.key)
  }
  useEffect(() => {
    localStorage.setItem('collapsed', JSON.stringify(collapsed))
  }, [collapsed])

  useEffect(() => {
    if (location.pathname === '/dashboard') setCurrentkey('1')
    if (location.pathname === '/consultants') setCurrentkey('2')
    if (location.pathname === '/inbox') setCurrentkey('3')
    if (location.pathname === '/users/list') setCurrentkey('4')
    if (location.pathname === '/my-profile') setCurrentkey('5')
  }, [])

  // Calculating total unread notifications
  useEffect(() => {
    ;(async () => {
      const notifications = await getNotifications(auth.user.token)

      if (notifications) {
        // map over read_by array if the value is equal one then add one to unreadNotification
        // This is working for now because, on get request, backend not sending all the users who read the notification, but only sending own id, if its available at read_by array
        const unread = notifications.filter(
          (notification) => notification.read_by.length === 0,
        )
        setUnreadNotification(unread.length)
      }
    })()
  }, [])

  useEffect(() => {
    const handlar = () => {
      setScreen(window.innerWidth)
    }
    window.addEventListener('resize', handlar)
    if (screen >= 768) {
      // on large screen
    } else {
      setCollapsed(true)
    }
    return () => {
      window.removeEventListener('resize', handlar)
    }
  }, [screen])

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider
        id="authorized"
        breakpoint="lg"
        collapsedWidth="65"
        trigger={null} //
        collapsible
        collapsed={collapsed} // state value (Can't initialize this value if auto collapse is required)
        // style={{ backgroundColor: '#3E9C58' }
        style={{ backgroundColor: '#9e1068' }}
        width="230"
        onBreakpoint={(broken) => {
          // console.log(broken)
        }}
        onCollapse={(collapsed, type) => {}}
      >
        <div style={styles.logoContainer}>
          <div
            style={{ display: 'flex', alignItems: 'center', height: '62px' }}
          >
            {/* <img
              // style={{ width: '80px', height: '76px' }}
              src="https://stco.s3.amazonaws.com/all/timestamp-1644153797678.png"
              alt="stco-logo"
              className="stco-logo"
            />
            <img
              style={{ width: '106px', height: '56px' }}
              src="https://stco.s3.amazonaws.com/all/timestamp-1644086179888.png"
              alt="stco-logo"
              className="stco-logo"
            /> */}
            <Title level={3}>Control</Title>
          </div>
        </div>
        <Menu
          theme="light"
          mode="inline"
          onClick={handleClick}
          selectedKeys={[currentkey]}
          //defaultSelectedKeys={['1']} // TODO: try to make it dynamic later, if needed.
          style={{ color: '#fff' }}
        >
          <Menu.Item
            key="1"
            // icon={<PieChartOutlined />}
            style={{ color: '#fff' }}
            // onClick={() => navigate('/dashboard')}
          >
            <Link to="/dashboard">
              <span style={{ color: '#fff', fontWeight: 'normal' }}>
                All Users
              </span>
            </Link>
          </Menu.Item>

          <Menu.Item
            key="3"
            onClick={() => setCurrentkey('3')}
            // icon={
            //   <Badge count={user.totalUnreadMessages} size="small">
            //     <MessageOutlined style={{ color: '#fff' }} />
            //   </Badge>
            // }
          >
            <Link to="/manageusers">
              <span style={{ color: '#fff', fontWeight: 'normal' }}>
                Manage Users
              </span>
            </Link>
          </Menu.Item>

          <Menu.Item
            key="4"
            // icon={<FileSearchOutlined />}
            style={{ color: '#fff' }}
            // onClick={() => navigate('/users/list')}
          >
            <Link to="/logs">
              {' '}
              <span style={{ color: '#fff', fontWeight: 'normal' }}>Logs</span>
            </Link>
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout className="site-layout">
        <Header
          className="site-layout-background"
          style={{
            padding: 10,
            backgroundColor: '#fff',
            boxShadow: '3px 1px 5px -2px #000000',
            zIndex: 1,
            lineHeight: 'normal',
            height: `auto`,
          }}
        >
          <Row
            style={{
              width: '100%',
              // border: '1px solid red',
            }}
          >
            <Col span={18} style={{ display: 'flex', alignItems: 'center' }}>
              {collapsed ? (
                <MenuUnfoldOutlined
                  onClick={toggle}
                  style={{ color: '#9e1068' }}
                />
              ) : (
                <MenuFoldOutlined
                  onClick={toggle}
                  style={{ color: '#9e1068' }}
                />
              )}
            </Col>
            <Col
              span={6}
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: 'auto',
                  marginRight: 3,
                  cursor: 'pointer',
                }}
              >
                {/* <SearchOutlined style={{ fontSize: 20 }} /> */}
              </div>

              <div className="">
                <Dropdown overlay={menu} placement="bottomLeft">
                  <Avatar
                    size={{ xs: 18, sm: 22, md: 28, lg: 30, xl: 32 }}
                    src={
                      auth.user?.user?.picture !== undefined
                        ? auth.user?.user?.picture
                        : userImg
                    }
                    style={{ border: '1px solid #0FA958' }}
                    className="user-avatar"
                  >
                    <UserOutlined className="user-avatar" />
                  </Avatar>
                </Dropdown>
                <DownOutlined />
              </div>
            </Col>
          </Row>
        </Header>
        <Content className="maincontent" style={{ backgroundColor: '#fff' }}>
          {props.children}
        </Content>
      </Layout>
    </Layout>
  )
}

const styles = {
  logoCircle: {
    display: 'flex',
    color: '#fff',
    borderRadius: '50%',
    padding: 5,
    background: '#9e1068',
    width: 30,
    height: 30,
    alignItems: 'center',
    justifyContent: 'center',
  },
  logoContainer: {
    width: '100%',
    backgroundColor: '#F3F3F3',
    padding: '4px 10px',
  },
}

const mapStateToProps = (state, ownState) => {
  return {
    auth: state.auth,
    socket: state.socket.socket,
    user: state.user,
  }
}

const mapDispatchToProps = { joinSocket, calcTotalUnreadConv }

export default connect(mapStateToProps, mapDispatchToProps)(AuthorizationLayout)
