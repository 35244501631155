import React from 'react'
import { Button, Space } from 'antd'
import { useNavigate } from 'react-router-dom'
import { Row, Col } from 'antd'

function Header() {
  const navigate = useNavigate()

  return (
    <Row
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        padding: `2rem`,
      }}
    >
      <Col xs={24} sm={18} md={18} lg={15}>
        <h1
          style={{
            fontSize: '46px',
            fontWeight: 'bold',
          }}
        >
          You are not <br />
          alone...
        </h1>
        <p
          style={{
            fontSize: '1.2rem',
            color: 'gray',
            fontWeight: '400',
          }}
        >
          Shoulder To Cry On provides progressive, and affordable
          <br />
          healthcare, accessible on mobile and online for everyone
        </p>

        <Space wrap>
          <Button
            onClick={() => navigate('/signup')}
            style={{
              border: '0px solid',
              borderRadius: '4px',
              padding: '5px 55px 5px 55px',
              backgroundColor: '#367540',
              textAlign: 'top',
              color: 'white',
            }}
          >
            Sign up
          </Button>

          <Button
            onClick={() => navigate('/login')}
            style={{
              border: '0px solid',
              borderRadius: '4px',
              padding: '5px 55px 5px 55px',
              textAlign: 'top',
              color: '#367540',
            }}
          >
            Login
          </Button>
        </Space>
      </Col>

      <Col xs={24} sm={6} md={6} lg={9}>
        <div
          style={{
            width: '100%',
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img
            style={{
              width: '50%',
              height: '220px',
              display: 'block',
              objectFit: 'contain',
            }}
            src="https://stco.s3.amazonaws.com/all/timestamp-1644153797678.png"
            alt="large-logo"
            width="100%"
          />
          <img
            style={{
              width: '50%',
              height: '100px',
              objectFit: 'contain',
            }}
            src="https://stco.s3.amazonaws.com/all/timestamp-1644086179888.png"
            alt="stco-logo"
          />
        </div>
      </Col>
    </Row>
  )
}

export default Header
