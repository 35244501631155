import { notification } from 'antd'
import { SET_SOCKET } from '../types'
import io from 'socket.io-client'
import { API } from '../../api/index'
import { calcTotalUnreadConv } from '../user/user.action'

let socket

export const joinSocket = (obj, history) => async (dispatch, getState) => {
  try {
    // console.log('Socket joined ❤ joinSocket()')
    const url = process.env.REACT_APP_SERVER

    socket = io(`${url}?userId=${getState().auth.user.user._id}`, {
      transports: ['websocket'],
    })

    // get live messages
    socket?.on('privateMessageNotification', async (message) => {
      if (!getState().socket.showLiveMessageNotification) return // don't send notification, if this is false

      // determine if the message is from my side or other side
      if (getState().auth.user.user._id === message.from) return // Don't send notificaiton to own self

      // get the other side user userId
      const oppsiteUser =
        getState().auth.user.user._id === message.from
          ? message.to
          : message.from

      // Get user name from userId
      const response = await API({
        Authorization: `Bearer ${getState().auth.user?.token}`,
      }).get(`/api/v1/user/getConsultantinfo/${oppsiteUser}`, {})

      openNotificationWithIcon(
        'success',
        response.data.consultant.name,
        message.message,
      )
      dispatch(calcTotalUnreadConv()) // to re-calculate and update the badge
    })

    dispatch({ type: SET_SOCKET, payload: socket })
  } catch (err) {
    console.log('joinSocket() error')
  }
}

const openNotificationWithIcon = (type, from, msg) => {
  notification[type]({
    message: `New message!`,
    description: `${from}: ${msg}`,
  })
}
