import axios from 'axios'

export const uploadFiles = async (options, token, to, setFileProgress) => {
  try {
    const baseUrl = process.env.REACT_APP_SERVER

    const { onSuccess, onError, file, onProgress } = options
    const fmData = new FormData()

    const config = {
      headers: {
        'content-type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
      onUploadProgress: (event) => {
        console.log(event)
        const percent = Math.floor((event.loaded / event.total) * 100)
        console.log(percent)
        // setImgProgress(percent)
        setFileProgress(percent)
        if (percent === 100) {
          setTimeout(() => setFileProgress(0), 1000)
        }
        onProgress({ percent: (event.loaded / event.total) * 100 })
      },
    }

    fmData.append('file', file)
    fmData.append('to', to)

    try {
      const res = await axios.post(
        `${baseUrl}/api/v1/user/uploadChatAttachement`,
        fmData,
        config,
      )

      console.log(res.data)

      onSuccess('Ok')
      // console.log('server res: ', res)
    } catch (err) {
      console.log(err)
      const error = new Error('Some error')
      onError({ err })
    }

    // return response.data.consultant
  } catch (err) {
    console.log(err)
  }
}

export const uploadFilesGroup = async (options, token, to, setFileProgress) => {
  try {
    const baseUrl = process.env.REACT_APP_SERVER

    const { onSuccess, onError, file, onProgress } = options
    const fmData = new FormData()

    const config = {
      headers: {
        'content-type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
      onUploadProgress: (event) => {
        console.log(event)
        const percent = Math.floor((event.loaded / event.total) * 100)
        console.log(percent)
        // setImgProgress(percent)
        setFileProgress(percent)
        if (percent === 100) {
          setTimeout(() => setFileProgress(0), 1000)
        }
        onProgress({ percent: (event.loaded / event.total) * 100 })
      },
    }

    fmData.append('file', file)
    fmData.append('to', to)

    try {
      const res = await axios.post(
        `${baseUrl}/api/v1/user/uploadChatAttachementGroup`,
        fmData,
        config,
      )

      console.log(res.data)

      onSuccess('Ok')
      // console.log('server res: ', res)
    } catch (err) {
      console.log(err)
      const error = new Error('Some error')
      onError({ err })
    }

    // return response.data.consultant
  } catch (err) {
    console.log(err)
  }
}
