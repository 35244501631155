import React from 'react'
import { useNavigate, Navigate } from 'react-router-dom'
import { connect } from 'react-redux'

import Header from '../components/landing/Header'
import Services from '../components/landing/Services'
import PrototypeApp from '../components/landing/PrototypeApp'
import Apps from '../components/landing/Apps'
import { MainLayout } from '../layout/MainLayout'

const HomePage = (props) => {
  const navigate = useNavigate()

  return <Navigate to="/dashboard" />
}

const mapStateToProps = (state, ownState) => {
  return {
    auth: state.auth,
  }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(HomePage)
