import React from 'react'
import { MainLayout } from './layout/MainLayout'
import { Provider } from 'react-redux'
import 'antd/dist/antd.css'
import './assets/styles/style.css'
import store from './redux/index'
import Routes from './routes/Routes'

function App() {
  return (
    <Provider store={store}>
      <Routes />
    </Provider>
  )
}

export default App
