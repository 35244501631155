import React from 'react'
import { Row, Col, Typography } from 'antd'
import { Link } from 'react-router-dom'

const { Paragraph, Title } = Typography

const items = [
  {
    key: '1',
    image: 'https://stco.s3.amazonaws.com/all/timestamp-1642513764392.png',
    heading: 'Search Therapist',
    content:
      'Choose from a broad range of therapists, who speicalize in unique areas of mental health',
  },
  {
    key: '2',
    image: 'https://stco.s3.amazonaws.com/all/timestamp-1642513787025.png',
    heading: 'Talk to another User',
    content:
      'Subscribe to speak with another User who has undergone your similar experiences',
  },
  {
    key: '3',
    image: 'https://stco.s3.amazonaws.com/all/timestamp-1642513804923.png',
    heading: 'Consultation',
    content:
      'Go completely annoymous with another user and truly have the freedom to open up',
  },
]

function PrototypeApp() {
  return (
    <Row
      style={{
        backgroundColor: '#fff',
        paddingTop: '1rem',
        paddingBottom: '1rem',
      }}
    >
      {/*To avoid too much messy code I have used classes to design the mapped card outputs*/}
      <Col className="services-generic-content" xs={24} sm={24} md={24} lg={24}>
        <Title level={1} style={{ marginBottom: 0 }}>
          Preface on this prototype app
        </Title>
        {/* <Paragraph style={{ fontSize: `1.2rem`, color: 'gray' }}>
          We provide to you the best choiches for you. Adjust it to your health
          needs and make sure your undergo treatment with our highly qualified
          doctors you can consult with us which type of service is suitable for
          your health
        </Paragraph> */}
      </Col>
      <Row
        style={{
          paddingBottom: '50px',
          width: '100%',
          justifyContent: 'center',
          padding: `2rem`,
        }}
        gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}
      >
        {/* {items.map((item) => {
          return (
            <Col xs={24} sm={24} md={8} lg={8} key={item.key}>
              <div id="return-value">
                <br />
                <img src={item.image} className="card-img-top" alt="" />
                <br />
                <h3 style={{ fontWeight: '600' }}>{item.heading}</h3>
                <br />
                <p style={{ padding: '10px' }}>{item.content}</p>
              </div>
            </Col>
          )
        })} */}
        <Col xs={24} sm={24} md={24} lg={24}>
          <div
            style={{
              fontSize: '1.2rem',
              // color: 'gray',
              // fontWeight: '400',
            }}
          >
            <p style={{ fontStyle: 'italic' }}>
              Long, long time ago, in a galaxy not too far away...
            </p>
            <br />
            <p style={{ fontStyle: 'italic', marginBottom: 0 }}>
              It was a period of mental unrest. The Emperor’s secret plan to
              kull all the shoulders were succeeding and the crying Rebellion
              Forces were facing imminent defeat.
            </p>
            <br />
            <p>
              Apologies for the off topic introduction that is from a universe
              different to DC Comics{' '}
              <a
                href={`https://ark-am.com/project/shoulder-to-cry-on`}
                target="_blank"
              >
                <span className="text-cyan-500 cursor-pointer">
                  [click here for the reference]
                </span>
              </a>{' '}
              , as this is the era of multiverses, but in all seriousness,
              Shoulder to Cry On is an application for those seeking help from
              the difficulties in life.
            </p>
            <br />
            <p>
              Originally, Shoulder to Cry On was not even an idea, but literally
              to cry on someone’s shoulder in order to vent the troubles and
              share the pain. This lead to birthing the project in creating an
              app where one can not only seek solace but also receive
              professional guidance in dealing with mental hardship. Moreover,
              the indiviudal is able to share stories with someone who has
              undergone similar life experiences and counsel one another to
              attain peace. We are in the process to determine if this can be
              achieved through a cost effective platform that is safe and
              secure. However, this is our aim and our goal is to create a
              reputable establishment, one in which the needy become the needed
              through respect, love, and grace. <b>Just a reminder</b>, this is
              a <b>prototype</b> of the application. So please use the app with
              that notion in mind and do not reveal any vunerable knowledge or
              input information for public view.
            </p>
            <br />
            <p>
              What makes Shoulder to Cry On different are the pathways through
              which one can receive the help. From licensed therapists to group
              therapy, from conversing with another user to going completely
              annoymous, the individual can seek aid from a broad range of
              avenues. Furthermore, the interface options are text, phone or
              video. Thus leaving the choice upto the user on how, when and with
              who he, she, or they would like to be helped.
            </p>
          </div>
        </Col>
      </Row>
    </Row>
  )
}

export default PrototypeApp
