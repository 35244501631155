import React from 'react'
import moment from 'moment'
import { connect } from 'react-redux'

import profilePic from '../assets/images/man1.png'

const Message = ({ own, msg, auth }) => {
  return auth?.user?.user?._id !== msg.from ? (
    <div style={styles.wraper}>
      <div style={styles.messageBoxMe}>
        <img style={styles.profilePic} src={profilePic} alt="" />
        <div style={{ marginLeft: '7px' }}>
          {msg.user_from && (
            <p style={{ margin: 0, margintop: 5, textAlign: 'left' }}>
              {msg.user_from[0].name}
            </p>
          )}
          {/* if message is image then show img else show text */}
          {!msg.message.includes('.jpeg') ? (
            <p style={styles.messageOther}>{msg.message}</p>
          ) : (
            <img src={msg.message} width="200" alt="" />
          )}
          <p
            style={{
              margin: '0px',
              color: 'rgb(135 135 135)',
              fontSize: `.9375rem`,
            }}
          >
            {moment(msg.date).format('h:mm a, MMMM Do YYYY')}
          </p>
        </div>
      </div>
    </div>
  ) : (
    <div style={styles.wraper}>
      <div style={styles.messageBoxOther}>
        {/* <img style={styles.profilePic} src={profilePic} alt="" /> */}
        <div style={{ marginLeft: '7px' }}>
          {msg.user_from && (
            <p style={{ margin: 0, margintop: 5, textAlign: 'right' }}>
              {msg.user_from[0].name}
            </p>
          )}

          {/* if message is image then show img else show text */}
          {!msg.message.includes('.jpeg') ? (
            <p style={styles.messageOther}>{msg.message}</p>
          ) : (
            <img src={msg.message} width="200" alt="" />
          )}
          <p
            style={{
              margin: '0px',
              color: 'rgb(135 135 135)',
              textAlign: 'right',
              fontSize: `.9375rem`,
            }}
          >
            {moment(msg.date).format('h:mm a, MMMM Do YYYY')}
          </p>
        </div>
      </div>
    </div>
  )
}

const styles = {
  wraper: {
    // // backgroundColor: 'white',
    // height: '70vh',
  },

  messageBoxMe: {
    display: 'flex',
    justifyContent: 'left',
  },
  messageMe: {
    maxWidth: '90%',
    backgroundColor: '#458FF6',
    padding: '10px',
    fontSize: '16px',
    color: '#FFFFFF',
    margin: '0px',
    borderRadius: '10px',
  },

  messageBoxOther: {
    display: 'flex',
    justifyContent: 'right',
  },
  messageOther: {
    maxWidth: '90%',
    backgroundColor: '#9C8585',
    padding: '10px',
    fontSize: '18px',
    color: '#FFFFFF',
    margin: '0px',
    borderRadius: '10px',
    marginLeft: '10%',
  },
  profilePic: {
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    padding: '5px',
    border: '1px solid white',
  },
}

const mapStateToProps = (state, ownState) => {
  return {
    auth: state.auth,
    user: state.user,
  }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Message)
