import {
  GET_CONSULTANTS_LOADING,
  SET_CONSULTANTS,
  GET_CONSULTANT_LOADING,
  SET_CONSULTANT,
  CLEAR_USER_DATA,
  GET_CONVERSATION_LOADING,
  SET_CONVERSATION,
  SET_INBOX,
  SET_TOTAL_UNREAD_MESSAGES,
  SET_GROUP_CHAT_LISTS,
} from '../types'

const initialState = {
  getConsultantLoading: false,
  consultants: null,
  tempConsultant: null,
  tempConversation: null,
  tempConversationLoading: false,
  tempInbox: null,
  totalUnreadMessages: 0, // To show badge on left sidebar
  groupChatLists: [],
}

export default function user(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case GET_CONSULTANTS_LOADING:
      return { ...state, getConsultantLoading: payload }
    case SET_CONSULTANTS:
      return { ...state, consultants: payload }
    case GET_CONSULTANT_LOADING:
      return { ...state, singleConsultantLoading: payload }
    case SET_CONSULTANT:
      return { ...state, tempConsultant: payload }
    case GET_CONVERSATION_LOADING:
      return { ...state, tempConversationLoading: payload }
    case SET_CONVERSATION:
      return { ...state, tempConversation: payload }
    case SET_INBOX:
      return { ...state, tempInbox: payload }
    case SET_TOTAL_UNREAD_MESSAGES:
      return { ...state, totalUnreadMessages: payload }
    case SET_GROUP_CHAT_LISTS:
      return { ...state, groupChatLists: payload }
    case CLEAR_USER_DATA:
      return { initialState }
    default:
      return state
  }
}
