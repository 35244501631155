import React from 'react'
import { Row, Col, Typography } from 'antd'

const { Paragraph } = Typography

const items = [
  {
    key: '1',
    image: 'https://stco.s3.amazonaws.com/all/timestamp-1642513764392.png',
    heading: 'Search Therapist',
    content:
      'Choose from a broad range of therapists, who speicalize in unique areas of mental health',
  },
  {
    key: '2',
    image: 'https://stco.s3.amazonaws.com/all/timestamp-1642513787025.png',
    heading: 'Talk to another User',
    content:
      'Subscribe to speak with another User who has undergone your similar experiences',
  },
  {
    key: '3',
    image: 'https://stco.s3.amazonaws.com/all/timestamp-1642513804923.png',
    heading: 'Consultation',
    content:
      'Go completely annoymous with another user and truly have the freedom to open up',
  },
]

function Services() {
  return (
    <Row
      style={{
        backgroundColor: '#fff',
        paddingTop: '1rem',
        paddingBottom: '1rem',
      }}
    >
      {/*To avoid too much messy code I have used classes to design the mapped card outputs*/}
      <Col className="services-generic-content" xs={24} sm={24} md={24} lg={24}>
        <h1 style={{ fontWeight: 'bold' }}>Our Services</h1>
        {/* <Paragraph style={{ fontSize: `1.2rem`, color: 'gray' }}>
          We provide to you the best choiches for you. Adjust it to your health
          needs and make sure your undergo treatment with our highly qualified
          doctors you can consult with us which type of service is suitable for
          your health
        </Paragraph> */}
      </Col>
      <Row
        style={{
          paddingBottom: '50px',
          width: '100%',
          justifyContent: 'center',
          padding: `2rem`,
        }}
        gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}
      >
        {items.map((item) => {
          return (
            <Col xs={24} sm={24} md={8} lg={8} key={item.key}>
              <div id="return-value">
                <br />
                <img src={item.image} className="card-img-top" alt="" />
                <br />
                <h3 style={{ fontWeight: '600' }}>{item.heading}</h3>
                <br />
                <p style={{ padding: '10px' }}>{item.content}</p>
              </div>
            </Col>
          )
        })}
      </Row>
    </Row>
  )
}

export default Services
