import React from 'react'
import { Layout } from 'antd'

import { Footers } from './common/Footers'
import { NavBar } from './common/NavBar'

const { Content } = Layout

export const MainLayout = (props) => {
  return (
    <Layout>
      {/* <NavBar /> */}
      <Content className="maincontent">{props.children}</Content>
      {/* <Footers /> */}
    </Layout>
  )
}
