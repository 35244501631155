import React, { useState, useEffect } from 'react'
import { Form, Input, Radio, Button } from 'antd'
import { connect } from 'react-redux'
import { Row, Col, Typography, Alert, Space, Divider, Spin } from 'antd'
import GoogleLogin from 'react-google-login'
import { Navigate, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { GoogleOutlined, FacebookOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'

import { MainLayout } from '../layout/MainLayout'
import { API } from '../api'
import { SET_USER } from '../redux/types'

const { Title } = Typography

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 24 },
}

const VerifyEmail = (props) => {
  const { auth } = props

  const dispatch = useDispatch()
  const [token, setToken] = useState(useParams().token) // reset password token from mail inbox
  const [redirect, setRedirect] = useState(false)

  const verifyReq = async () => {
    try {
      //
      const response = await API({}).get(`api/v1/auth/verifyEmail/${token}`)

      console.log(response.data)
      dispatch({ type: SET_USER, payload: response.data })
    } catch (err) {
      console.log(err)
      alert('Something went wrong, try again.')
      setRedirect(true) // temporarily later show approriate message and retry options
    }
  }

  useEffect(() => {
    if (token) {
      verifyReq()
    }
  }, [])

  if (props.auth.user) {
    return <Navigate to="/dashboard" />
  }

  if (redirect) return <Navigate to="/" />

  return (
    <MainLayout>
      <Row
        style={{
          minHeight: '500px',
          justifyContent: 'center',
          filter: `drop-shadow(2 1 0.15rem grey)`,
          padding: `2rem`,
        }}
      >
        <Col xs={{ span: 0 }} md={{ span: 10 }} lg={{ span: 10 }}>
          <div
            style={{
              backgroundColor: '#9e1068',
              borderRadius: 20,
              paddingTop: 20,
              paddingLeft: 20,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              paddingBottom: 20,
              height: '100%',
              marginRight: -25,
            }}
          >
            <div>
              <Title
                level={3}
                style={{
                  color: '#fff',
                }}
              >
                Getting <br /> Started With
              </Title>

              <div
                style={{
                  display: 'flex',
                  fontWeight: 'bold',
                  fontSize: 24,
                  alignItems: 'center',
                }}
              >
                <img
                  style={{ height: '50px' }}
                  src="https://stco.s3.amazonaws.com/all/timestamp-1644153797678.png"
                  alt="stco-logo"
                />
                <img
                  src="https://stco.s3.amazonaws.com/all/timestamp-1644086179888.png"
                  alt=""
                />
              </div>
            </div>
            <div>
              <p style={{ color: '#fff', marginBottom: 0, fontSize: 19 }}>
                You are not alone...
              </p>
              <p style={{ color: '#fff', marginBottom: 0, fontSize: 19 }}>
                We are here to help you.
              </p>
            </div>
          </div>
        </Col>
        <Col
          xs={{ span: 24 }}
          md={{ span: 14 }}
          lg={{ span: 14 }}
          style={{
            backgroundColor: '#fff',
            borderRadius: 20,
            // marginLeft: -30,
            // border: '1px solid green',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <h1>Verify email</h1>
          <p>Please wait...</p>
          <div
            style={{
              margin: '20px 0',
              padding: '30px 50px',
              textAlign: 'center',
            }}
          >
            <Spin size="large" />
          </div>
          <div className="have_an_account" style={{ marginBottom: 7 }}>
            <span>
              Link expired?
              <Link to="/signup" style={{ color: `#9e1068` }}>
                Try again
              </Link>
            </span>
          </div>
        </Col>
      </Row>
    </MainLayout>
  )
}

const mapStateToProps = (state, ownState) => {
  return {
    auth: state.auth,
  }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(VerifyEmail)
