import React, { useState, useEffect } from 'react'
import {
  Spin,
  Typography,
  Row,
  Col,
  Image,
  Divider,
  Rate,
  Card,
  Avatar,
} from 'antd'
import { connect, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
  CheckOutlined,
  MessageOutlined,
  CloseCircleOutlined,
  PhoneOutlined,
  ClockCircleOutlined,
  MedicineBoxOutlined,
  UserOutlined,
} from '@ant-design/icons'

import AuthorizationLayout from '../layout/AuthorizationLayout.jsx'

import { getConsultantInfo } from '../redux/user/user.action'
import ConProfileTopRow from '../components/common/ConProfileTopRow.js'

const { Title, Text, Link } = Typography

const ConsultantSingle = (props) => {
  const { auth, user, getConsultantInfo } = props
  const { singleConsultantLoading, tempConsultant } = user
  const [id, setId] = useState(useParams().id)

  const dispatch = useDispatch()

  useEffect(() => {
    getConsultantInfo(id)
  }, [])

  return (
    <AuthorizationLayout>
      <ConProfileTopRow user={tempConsultant} />
      <Row style={{ margin: 10, padding: 5, boxShadow: '1px 1px 3px #333' }}>
        {singleConsultantLoading && <Spin />}
        <Title level={4} style={{ marginBottom: 0 }}>
          Overview
        </Title>
        <Divider style={{ margin: 10 }} />
        {tempConsultant && (
          <>
            <Col xs={24} sm={4} md={6} lg={18} xl={18}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Title level={4}>About</Title>

                <Text>
                  Licensed Mental Health Counselor. Lorem ipsum dolor sit amet
                  consectetur, adipisicing elit. Excepturi ducimus, commodi
                  veritatis, expedita eum obcaecati et perferendis deserunt aut
                  illo veniam praesentium nostrum eligendi? Ratione eius fugiat
                  expedita alias tempora. Lorem ipsum, dolor sit amet
                  consectetur adipisicing elit. Molestias commodi impedit dicta
                  at quae corporis consequuntur fugit repellendus. Pariatur
                  explicabo, voluptates magni delectus quod necessitatibus
                  temporibus eum hic repellat unde.
                </Text>
              </div>
            </Col>
            <Col xs={20} sm={16} md={12} lg={6} xl={6}>
              <Title level={4}>Fee</Title>
              {tempConsultant.perHour
                ? `$${tempConsultant.perHour} /hr`
                : 'N/A '}
              <Title level={4}>License No.</Title>
              {tempConsultant.consultantRegistration
                ? `${tempConsultant.consultantRegistration}`
                : 'N/A '}
            </Col>
          </>
        )}
      </Row>
      {/* <Row style={{ margin: 10, padding: 5, boxShadow: '1px 1px 3px #333' }}>
        {singleConsultantLoading && <Spin />}
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            // paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          <Title level={5} style={{ margin: 0 }}>
            Reviews
          </Title>
          <Title level={5} style={{ margin: 0 }}>
            <Rate disabled defaultValue={1} count={1} /> <span>4.9(21)</span>
          </Title>
        </div>

        <Divider style={{ margin: 10 }} />
        {tempConsultant && (
          <>
            <Col
              xs={24}
              lg={24}
              xl={24}
              lg={24}
              xl={24}
              style={{
                width: '100%',
              }}
            >
              <Row gutter={[16, 24]}>
                {[...Array(5)].map((val, idx) => {
                  return (
                    <Col
                      xs={24}
                      sm={4}
                      md={6}
                      lg={8}
                      xl={8}
                      style={{
                        width: '100%',
                      }}
                    >
                      <Card
                        key={idx}
                        style={{
                          backgroundColor: '#F4F5F8',
                          boxShadow: '0px 0px 3px #333',
                        }}
                        bodyStyle={{ padding: 5 }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <div style={{ display: 'flex' }}>
                            <Avatar
                              size={{
                                xs: 24,
                                sm: 32,
                                md: 40,
                                lg: 50,
                                xl: 45,
                                xxl: 50,
                              }}
                              style={{
                                border: '1px solid #0FA958',
                                marginRight: 3,
                              }}
                            >
                              <UserOutlined />
                            </Avatar>
                            <div>
                              <Title level={5} style={{ margin: 0 }}>
                                Aslam Sheikh
                              </Title>
                              <Text style={{ margin: 0 }}>1 January 2022</Text>
                            </div>
                          </div>
                          <div>
                            <Rate disabled defaultValue={1} count={1} />{' '}
                            <span>4.9</span>
                          </div>
                        </div>
                        <Divider style={{ margin: 10 }} />
                        <Text>
                          Kamal Hossain is the biggest highlight Lorem ipsum
                          dolor, sit amet consectetur adipisicing elit. Nisi,
                          numquam?...
                        </Text>
                      </Card>
                    </Col>
                  )
                })}
              </Row>
            </Col>
          </>
        )}
      </Row> */}
      <Row>
        <Col>
          <div style={{ height: 10 }}></div>
        </Col>
      </Row>
    </AuthorizationLayout>
  )
}

const mapStateToProps = (state, ownState) => {
  return {
    auth: state.auth,
    user: state.user,
  }
}

const mapDispatchToProps = { getConsultantInfo }

export default connect(mapStateToProps, mapDispatchToProps)(ConsultantSingle)
