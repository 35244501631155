import React, { useState, useEffect } from 'react'
import {
  Form,
  Input,
  Button,
  Col,
  Row,
  TimePicker,
  DatePicker,
  Typography,
  Select,
  Tooltip,
  Upload,
} from 'antd'
import { connect, useDispatch } from 'react-redux'
import {
  MinusCircleOutlined,
  PlusOutlined,
  DollarOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons'
import {
  CountryDropdown,
  RegionDropdown,
  CountryRegionData,
} from 'react-country-region-selector'
import moment from 'moment'
import axios from 'axios'
import ImgCrop from 'antd-img-crop'

import { API } from '../api'
import AuthorizationLayout from '../layout/AuthorizationLayout.jsx'

import { getConsultantInfo } from '../redux/user/user.action'
import { updateProfile } from '../redux/auth/auth.action'

const { Paragraph } = Typography

const dateFormat = 'YYYY-MM-DD'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    md: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
}
const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 20, offset: 4 },
  },
}
const { Title } = Typography
const { Option } = Select

const days = [
  <Option key={`Saturday`}>{`Saturday`}</Option>,
  <Option key={`Sunday`}>{`Sunday`}</Option>,
  <Option key={`Monday`}>{`Monday`}</Option>,
  <Option key={`Tuesday`}>{`Tuesday`}</Option>,
  <Option key={`Wednesday`}>{`Wednesday`}</Option>,
  <Option key={`Thursday`}>{`Thursday`}</Option>,
  <Option key={`Friday`}>{`Friday`}</Option>,
]
const children = [
  <Option key={`Atlanta`}>{`Atlanta`}</Option>,
  <Option key={`Dhaka`}>{`Dhaka`}</Option>,
]
for (let i = 10; i < 36; i++) {
  // children.push(<Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>)
}

const validateMessages = {
  required: '${label} is required!',
  types: {
    email: '${label} is not a valid email!',
    number: '${label} is not a valid number!',
  },
  number: {
    range: '${label} must be between ${min} and ${max}',
  },
}

// const onFinish = (values) => {
//   console.log('Success:', values)
// }

const UserProfile = (props) => {
  const { auth, user, getConsultantInfo, updateProfile } = props
  const { tempConsultant } = user

  const dispatch = useDispatch()

  const [name, setName] = useState('')
  const [about, setAbout] = useState('')
  const [email, setEmail] = useState('')
  const [driving, setDriving] = useState('')
  const [consultantRegistration, setConsultantRegistration] = useState('')
  const [specialist, setSpecialist] = useState('')
  const [workLoc, setWorkLoc] = useState('')
  const [phone, setPhone] = useState('')
  const [country, setCountry] = useState('')
  const [region, setRegion] = useState('')
  const [dob, setDob] = useState(null)
  const [perHour, setPerHour] = useState('0')
  const [licensingBoard, setLicensingBoard] = useState([])
  const [offDays, setOffDays] = useState([])
  const [startTime, setStartTime] = useState(null)
  const [endTime, setEndTime] = useState(null)
  const [imgProgress, setImgProgress] = useState(0)
  const [fileList, setFileList] = useState([])

  const onChangeImgUpload = ({ fileList: newFileList }) => {
    console.log(newFileList)
    if (newFileList.length === 0) {
      updateProfile({ picture: null })
      // TODO: currently not deleting picture from s3, later delete images from s3 before updating profile object, otherwise too much untracked images in s3 will be stored (Creating new route for this will be more efficient, rather than using this updateProfile function)
    }
    setFileList(newFileList)
  }

  const onPreview = async (file) => {
    let src = file.url
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader()
        reader.readAsDataURL(file.originFileObj)
        reader.onload = () => resolve(reader.result)
      })
    }
    const image = new Image()
    image.src = src
    const imgWindow = window.open(src)
    imgWindow.document.write(image.outerHTML)
  }

  const startTimeChange = (value) => {
    if (!value) {
      updateProfile({ startTime: null })
      // Remove the end time because if start time changed, then user must have to re initialized the end time
      setEndTime(null)
      updateProfile({ endTime: null })
      return
    }
    // Remove the end time because if start time changed, then user must have to re initialized the end time
    setEndTime(null)
    updateProfile({ endTime: null })
    setStartTime(moment(value).format('HH:mm:ss')) // for state
    updateProfile({ startTime: moment(value).format('HH:mm:ss') }) // for backend
  }

  const endTimeChange = (value) => {
    if (!value) {
      updateProfile({ endTime: null })
      return
    }
    setEndTime(moment(value).format('HH:mm:ss')) // for state
    updateProfile({ endTime: moment(value).format('HH:mm:ss') }) // for backend
  }

  // Consultant License board selector
  function handleChange(value) {
    setLicensingBoard(value)
    updateProfile({ licensingBoard: value })
  }

  function handleOffDays(value) {
    setOffDays(value)
    updateProfile({ offDays: value })
  }

  useEffect(() => {
    getConsultantInfo(auth?.user?.user?._id)
    return () => {
      dispatch({ type: 'SET_CONSULTANT', payload: null }) // Unsetting the user from redux, to show next user properly (if needed)
    }
  }, [])

  useEffect(() => {
    if (tempConsultant) {
      // normal user and consultant user profile, both are in "tempConsultant"
      setName(tempConsultant.name)
      setAbout(tempConsultant.about)
      setEmail(tempConsultant.email)
      setDriving(tempConsultant.driving)
      setConsultantRegistration(tempConsultant.consultantRegistration)
      setSpecialist(tempConsultant.specialist)
      setWorkLoc(tempConsultant.workLoc)
      setPhone(tempConsultant.phone)
      setCountry(tempConsultant.country)
      setRegion(tempConsultant.region)
      if (tempConsultant.dob) {
        // to render add button rather than date picker component
        setDob(moment(tempConsultant.dob).format('YYYY-MM-DD'))
      }
      setPerHour(tempConsultant.perHour)
      setLicensingBoard(tempConsultant.licensingBoard)
      setOffDays(tempConsultant.offDays)
      setStartTime(tempConsultant.startTime)
      setEndTime(tempConsultant.endTime)
      if (tempConsultant.picture) {
        setFileList([
          {
            uid: '-1',
            name: ``,
            status: 'done',
            url: tempConsultant.picture,
          },
        ])
      }
    }
  }, [tempConsultant])

  const hanldeProfileUpdate = (v, k) => {
    console.log(v)
    console.log(k)
    if (k === 'name') {
      setName(v)
      updateProfile({ name: v })
    } else if (k === 'about') {
      setAbout(v)
      updateProfile({ about: v })
    } else if (k === 'driving') {
      setDriving(v)
      updateProfile({ driving: v })
    } else if (k === 'consultantRegistration') {
      setConsultantRegistration(v)
      updateProfile({ consultantRegistration: v })
    } else if (k === 'specialist') {
      setSpecialist(v)
      updateProfile({ specialist: v })
    } else if (k === 'workLoc') {
      setWorkLoc(v)
      updateProfile({ workLoc: v })
    } else if (k === 'phone') {
      setPhone(v)
      updateProfile({ phone: v })
    } else if (k === 'country') {
      setCountry(v)
      setRegion('')
      updateProfile({ country: v, region: '' })
    } else if (k === 'region') {
      setRegion(v)
      updateProfile({ region: v })
    } else if (k === 'dob') {
      setDob(moment(v).format('YYYY-MM-DD'))
      updateProfile({ dob: v })
    } else if (k === 'perHour') {
      setPerHour(v)
      updateProfile({ perHour: v })
    }
  }

  const uploadImage = async (options) => {
    const baseUrl = process.env.REACT_APP_SERVER

    // setUrl(null)
    const { onSuccess, onError, file, onProgress } = options
    const fmData = new FormData()

    const config = {
      headers: {
        'content-type': 'multipart/form-data',
        Authorization: `Bearer ${auth.user.token}`,
      },
      onUploadProgress: (event) => {
        const percent = Math.floor((event.loaded / event.total) * 100)
        // console.log(percent)
        setImgProgress(percent)
        if (percent === 100) {
          setTimeout(() => setImgProgress(0), 1000)
        }
        onProgress({ percent: (event.loaded / event.total) * 100 })
      },
    }
    fmData.append('image', file)
    fmData.append('pass', `kamalhahaha`)
    try {
      const res = await axios.post(
        `${baseUrl}/api/v1/user/upload`,
        fmData,
        config,
      )

      // const res = await API({
      //   Authorization: `Bearer token`,
      //   ...config,
      // }).post(`/api/v1/file/upload`, { ...fmData })

      // Store for showing link
      // if (res.data?.saved?.Location) setUrl(res.data.saved.Location)

      onSuccess('Ok')
      // console.log('server res: ', res)
    } catch (err) {
      console.log(err)
      alert(
        'Failed to upload, please re check your password or try again later.',
      )
      console.log('Eroor: ', err)
      const error = new Error('Some error')
      onError({ err })
    }
  }

  return (
    <AuthorizationLayout>
      <Form
        validateMessages={validateMessages}
        labelCol={{
          span: 24,
        }}
        // onFinish={onFinish}
        id="profile"
      >
        <Row
          gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
          id="userprofile"
          className="container container-general"
          style={{
            marginTop: '50px',
            padding: '20px 10px',
            border: '1px solid #e5e5e5',
            borderRadius: '8px',
          }}
        >
          <Col
            span={24}
            style={{ borderBottom: '1px solid #e5e5e5', marginBottom: '20px' }}
          >
            <Row>
              <Col
                xs={{ span: 16 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
                sm={{ span: 24 }}
              >
                <Title level={4}>Profile Information</Title>
              </Col>
              <Col
                xs={{ span: 8 }}
                md={{ span: 8 }}
                lg={{ span: 8 }}
                sm={{ span: 24 }}
              >
                <Form.Item style={{ float: 'right', marginBottom: '5px' }}>
                  {/* <Button
                    type="primary"
                    htmlType="submit"
                    style={{ marginBottom: '5px' }}
                  >
                    Update
                  </Button> */}
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col
            xs={{ span: 24 }}
            md={{ span: 8 }}
            lg={{ span: 8 }}
            sm={{ span: 24 }}
          >
            <Title level={5}>Full name</Title>

            <Paragraph
              style={styles.inputbox}
              editable={{ onChange: (n) => hanldeProfileUpdate(n, 'name') }}
            >
              {name}
            </Paragraph>
          </Col>
          <Col
            xs={{ span: 24 }}
            md={{ span: 8 }}
            lg={{ span: 8 }}
            sm={{ span: 24 }}
          >
            <Title level={5}>Email</Title>
            <Paragraph style={styles.inputbox} copyable>
              {email}
            </Paragraph>
          </Col>
          <Col
            xs={{ span: 24 }}
            md={{ span: 8 }}
            lg={{ span: 8 }}
            sm={{ span: 24 }}
          >
            <Title level={5}>Driving Licence</Title>
            <Paragraph
              style={styles.inputbox}
              editable={{ onChange: (n) => hanldeProfileUpdate(n, 'driving') }}
            >
              {driving}
            </Paragraph>
          </Col>

          <Col
            xs={{ span: 24 }}
            md={{ span: 8 }}
            lg={{ span: 8 }}
            sm={{ span: 24 }}
          >
            <Title level={5}>Contact Number</Title>
            <Paragraph
              style={styles.inputbox}
              editable={{
                onChange: (n) => hanldeProfileUpdate(n, 'phone'),
              }}
            >
              {phone}
            </Paragraph>
          </Col>
          <Col
            xs={{ span: 24 }}
            md={{ span: 8 }}
            lg={{ span: 8 }}
            sm={{ span: 24 }}
          >
            <Title level={5}>
              Date of Birth{' '}
              <Tooltip title="You can change this only limited amount of time.">
                <InfoCircleOutlined />
              </Tooltip>
            </Title>
            {dob ? (
              <Form.Item
                //  label="Date of Birth"
                name={['profile', 'dob']}
              >
                <DatePicker
                  defaultValue={moment(dob, 'YYYY-MM-DD')}
                  onChange={(val) => hanldeProfileUpdate(val, 'dob')}
                  placeholder="Date of Birth"
                  style={{
                    width: '100%',
                    borderRadius: '5px',
                    border: '1px solid gray',
                  }}
                  format="YYYY-MM-DD"
                />
              </Form.Item>
            ) : (
              <Button
                type="link"
                onClick={() => setDob(moment().format('YYYY-MM-DD'))}
              >
                Set birth date
              </Button>
            )}
          </Col>
          <Col
            xs={{ span: 24 }}
            md={{ span: 8 }}
            lg={{ span: 8 }}
            sm={{ span: 24 }}
          >
            <Title level={5}>Country</Title>
            <CountryDropdown
              style={{ maxWidth: '100%', padding: '4px' }}
              value={country}
              onChange={(val) => hanldeProfileUpdate(val, 'country')}
            />
          </Col>
          <Col
            xs={{ span: 24 }}
            md={{ span: 8 }}
            lg={{ span: 8 }}
            sm={{ span: 24 }}
          >
            <Title level={5}>Region</Title>
            <RegionDropdown
              country={country}
              style={{
                width: '100%',
                padding: '5px',
                border: '1px solid gray',
                borderRadius: '5px',
              }}
              value={region}
              disableWhenEmpty
              onChange={(val) => hanldeProfileUpdate(val, 'region')}
            />
          </Col>
          <Col span={24}>
            <Title level={5}>About</Title>
            <Paragraph
              style={styles.inputbox}
              editable={{ onChange: (n) => hanldeProfileUpdate(n, 'about') }}
            >
              {about}
            </Paragraph>
          </Col>

          {tempConsultant && tempConsultant.role === 'consultant' && (
            <>
              <Col
                xs={{ span: 24 }}
                md={{ span: 8 }}
                lg={{ span: 8 }}
                sm={{ span: 24 }}
              >
                <Title level={5}>Fee per hour</Title>
                <Paragraph
                  style={styles.inputbox}
                  editable={{
                    onChange: (n) => hanldeProfileUpdate(n, 'perHour'),
                  }}
                >
                  {perHour ? perHour : '0'} <DollarOutlined />
                </Paragraph>
              </Col>
              <Col
                xs={{ span: 24 }}
                md={{ span: 8 }}
                lg={{ span: 8 }}
                sm={{ span: 24 }}
              >
                <Title level={5}>License No.</Title>
                <Paragraph
                  style={styles.inputbox}
                  editable={{
                    onChange: (n) =>
                      hanldeProfileUpdate(n, 'consultantRegistration'),
                  }}
                >
                  {consultantRegistration}
                </Paragraph>
              </Col>
              <Col
                xs={{ span: 24 }}
                md={{ span: 8 }}
                lg={{ span: 8 }}
                sm={{ span: 24 }}
              >
                <Title level={5}>License type</Title>
                <Paragraph
                  style={styles.inputbox}
                  editable={{
                    onChange: (n) => hanldeProfileUpdate(n, 'specialist'),
                  }}
                >
                  {specialist}
                </Paragraph>
              </Col>

              <Col
                xs={{ span: 24 }}
                md={{ span: 8 }}
                lg={{ span: 8 }}
                sm={{ span: 24 }}
              >
                <Title level={5}>Licensing Board</Title>
                <Select
                  mode="multiple"
                  allowClear
                  style={{
                    width: '100%',
                  }}
                  placeholder="Please select"
                  value={licensingBoard}
                  onChange={handleChange}
                >
                  {children}
                </Select>
              </Col>
              <Col
                xs={{ span: 24 }}
                md={{ span: 8 }}
                lg={{ span: 8 }}
                sm={{ span: 24 }}
              >
                <Title level={5}>Works at</Title>
                <Paragraph
                  style={styles.inputbox}
                  editable={{
                    onChange: (n) => hanldeProfileUpdate(n, 'workLoc'),
                  }}
                >
                  {workLoc}
                </Paragraph>
              </Col>
              <Col
                xs={{ span: 12 }}
                md={{ span: 8 }}
                lg={{ span: 8 }}
                sm={{ span: 12 }}
              >
                <Title level={5}>Off days</Title>
                <Select
                  mode="multiple"
                  allowClear
                  style={{ width: '100%' }}
                  placeholder="Please select"
                  value={offDays}
                  onChange={handleOffDays}
                >
                  {days}
                </Select>
              </Col>
              <Col
                xs={{ span: 24 }}
                md={{ span: 8 }}
                lg={{ span: 8 }}
                sm={{ span: 24 }}
              >
                <Title level={5}>
                  Schedules{' '}
                  <Tooltip title="Time range when you are available for giving your consultation service. Except from your provided off days.">
                    <InfoCircleOutlined />
                  </Tooltip>
                </Title>
                {startTime ? (
                  <Form.Item label="Start Time" name={['profile', 'startTime']}>
                    <TimePicker
                      defaultValue={moment(startTime, 'HH:mm:ss')}
                      use12Hours
                      format="h:mm a"
                      onChange={startTimeChange}
                      value={startTime}
                      placeholder="Start Time"
                      style={{
                        width: '100%',
                        borderRadius: '5px',
                        border: '1px solid gray',
                      }}
                    />
                  </Form.Item>
                ) : (
                  <Button type="link" onClick={() => startTimeChange(moment())}>
                    Set start time
                  </Button>
                )}

                {endTime ? (
                  <Form.Item label="End Time" name={['profile', 'endTime']}>
                    <TimePicker
                      defaultValue={moment(endTime, 'HH:mm:ss')}
                      use12Hours
                      format="h:mm a"
                      onChange={endTimeChange}
                      value={endTime}
                      placeholder="End Time"
                      style={{
                        width: '100%',
                        borderRadius: '5px',
                        border: '1px solid gray',
                      }}
                    />
                  </Form.Item>
                ) : (
                  <Button type="link" onClick={() => endTimeChange(moment())}>
                    Set end time
                  </Button>
                )}
              </Col>
            </>
          )}

          <Col
            xs={{ span: 24 }}
            md={{ span: 8 }}
            lg={{ span: 8 }}
            sm={{ span: 24 }}
          >
            <Title level={5}>Payments profile</Title>
            <Paragraph
              style={styles.inputbox}
              editable={{ onChange: (n) => hanldeProfileUpdate(n, 'payment') }}
            >
              {/* {payment} */}
              Coming soon...
            </Paragraph>
          </Col>

          <Col
            xs={{ span: 24 }}
            md={{ span: 8 }}
            lg={{ span: 8 }}
            sm={{ span: 24 }}
          >
            <Title level={5}>Profile Picture</Title>

            <ImgCrop rotate>
              <Upload
                listType="picture-card"
                fileList={fileList}
                onPreview={onPreview}
                // beforeUpload={beforeUploadImage}
                customRequest={uploadImage}
                onChange={onChangeImgUpload}
              >
                {fileList.length < 1 && '+ Upload'}
              </Upload>
            </ImgCrop>
          </Col>
        </Row>
      </Form>
    </AuthorizationLayout>
  )
}

const styles = {
  inputbox: {
    border: '1px solid gray',
    padding: '4px',
    borderRadius: '5px',
  },
}
const mapStateToProps = (state, ownState) => {
  return {
    auth: state.auth,
    user: state.user,
  }
}

const mapDispatchToProps = { getConsultantInfo, updateProfile }

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile)
