export const SIGNUP_LOADING = 'SIGNUP_LOADING'
export const SIGNUP_ERROR = 'SIGNUP_ERROR'
export const SET_USER = 'SET_USER'
export const UPDATE_USER_INFO_NOT_TOKEN = 'UPDATE_USER_INFO_NOT_TOKEN'
export const LOGIN_LOADING = 'LOGIN_LOADING'
export const LOGIN_ERROR = 'LOGIN_ERROR'
export const LOGOUT = 'LOGOUT'
export const CLEAR_USER_DATA = 'CLEAR_USER_DATA'
export const FORGOT_PASS_RESPONSE = 'FORGOT_PASS_RESPONSE'

export const GET_CONSULTANTS_LOADING = 'GET_CONSULTANTS_LOADING'
export const SET_CONSULTANTS = 'SET_CONSULTANTS'
export const GET_CONSULTANT_LOADING = 'GET_CONSULTANT_LOADING'
export const SET_CONSULTANT = 'SET_CONSULTANT'
export const GET_CONVERSATION_LOADING = 'GET_CONVERSATION_LOADING'
export const SET_CONVERSATION = 'SET_CONVERSATION'
export const SET_INBOX = 'SET_INBOX'
export const SET_GROUP_CHAT_LISTS = 'SET_GROUP_CHAT_LISTS'

export const SET_SOCKET = 'SET_SOCKET'
export const TOOGLE_LIVE_NOTIFICATION = 'TOOGLE_LIVE_NOTIFICATION'
export const SET_TOTAL_UNREAD_MESSAGES = 'SET_TOTAL_UNREAD_MESSAGES'
