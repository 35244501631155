import {
  SIGNUP_LOADING,
  SET_USER,
  UPDATE_USER_INFO_NOT_TOKEN,
  SIGNUP_ERROR,
  LOGIN_LOADING,
  LOGIN_ERROR,
  LOGOUT,
  FORGOT_PASS_RESPONSE,
} from '../types'

const userData = localStorage.getItem('userData')

const initialState = {
  signupLoading: false,
  signupErr: null,
  user: JSON.parse(userData),
  loginLoading: false,
  loginErr: null,
  forgotPassResponse: null,
}

export default function auth(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case SIGNUP_LOADING:
      return { ...state, signupLoading: payload }
    case SIGNUP_ERROR:
      return { ...state, signupErr: payload }
    case SET_USER:
      localStorage.setItem('userData', JSON.stringify(payload))
      return { ...state, user: payload }
    // Just updating the user info without token
    case UPDATE_USER_INFO_NOT_TOKEN:
      localStorage.setItem(
        'userData',
        JSON.stringify({ ...state.user, user: payload }),
      )
      return { ...state, user: { ...state.user, user: payload } }
    case LOGIN_LOADING:
      return { ...state, loginLoading: payload }
    case LOGIN_ERROR:
      return { ...state, loginErr: payload }
    case FORGOT_PASS_RESPONSE:
      return { ...state, forgotPassResponse: payload }
    case LOGOUT:
      localStorage.removeItem('user')
      localStorage.removeItem('collapsed')
      return { initialState, user: null }
    default:
      return state
  }
}
