import React, { useEffect, useState } from 'react'
import {
  Typography,
  List,
  Avatar,
  Skeleton,
  Spin,
  Collapse,
  Select,
  Slider,
  Input,
  Divider,
  Row,
  Col,
  Tag,
} from 'antd'
import { connect, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { MessageOutlined } from '@ant-design/icons'
import InfiniteScroll from 'react-infinite-scroll-component'
import { API } from '../api/index'
import { CloseCircleOutlined } from '@ant-design/icons'
import { UserOutlined, FilterOutlined } from '@ant-design/icons'
import AuthorizationLayout from '../layout/AuthorizationLayout.jsx'
const { Search } = Input
const { Title } = Typography
const { Panel } = Collapse
const { Option } = Select
const userImg = `https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png`
const Users = (props) => {
  const { auth } = props

  const [loading, setLoading] = useState(false) // loading spinner
  const [data, setData] = useState([]) // total users data
  const [totalNormalUsers, setTotalNormalUsers] = useState(0) // counting total data
  const [srcTxt, setSrcTxt] = useState('')

  const handleSearch = async (txt) => {
    setTotalNormalUsers(0)
    setData([])
    if (txt === ' ') return // more comprehensive checks can be added here later on.

    // Store text in search state
    setSrcTxt(txt)
    console.log(txt)
    // checking if not an empty string
    if (!!txt) {
      // Send search query at backend
      const response = await API({
        Authorization: `Bearer ${auth.user?.token}`,
      }).get(`/api/v1/user/searchUser?keyword=${txt}&user=true`, {})

      // store remote search results, to render as list
      if (response?.data?.users?.length > 0) {
        setData(response.data.users)
        console.log(response.data)
      }
    } else {
      // TODO: remove this reload(), remove data array properly, and fetch list as previous
      window.location.reload()
    }
  }

  const loadMoreData = async () => {
    if (loading) {
      return
    }
    setData([])
    setLoading(true)

    try {
      const response = await API({
        Authorization: `Bearer ${auth.user?.token}`,
      }).get(`/api/v1/user/getAllUsers?limit=${10000}&skip=${data.length}`, {})

      setData([
        ...data,
        ...response.data.users.filter(
          (el, idx) => el._id !== props.auth.user.user._id,
        ), // Removing myself from the array
      ])

      setTotalNormalUsers(response.data.totalNormalUsers - 1) // removing my count from array, because my object is removed above

      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  const selectOnChange = (e) => {
    console.log(e)
  }

  const selcetedTag = (e) => {}

  const close = () => {
    console.log()
  }
  const dispatch = useDispatch()

  useEffect(() => {
    loadMoreData()
  }, [])

  return (
    <AuthorizationLayout>
      <div style={{ margin: 10, padding: 5, boxShadow: '1px 1px 3px #333' }}>
        <Title level={5}>All Users List {data.length}</Title>

        <Search
          placeholder="Search frined's by Name"
          allowClear
          enterButton="Search"
          // onSearch={handleSearche}
          size="large"
          onChange={(evt) => handleSearch(evt.target.value)}
        />
        <div
          id="scrollableDiv"
          style={{
            height: 600,
            overflow: 'auto',
            padding: '0 16px',
            border: '1px solid rgba(140, 140, 140, 0.35)',
          }}
        >
          {/* <p>Loaded: {JSON.stringify(data.length)}</p>
          <p>Total: {JSON.stringify(totalNormalUsers)}</p> */}
          {loading ? (
            <div className="spiner-center">
              <Skeleton avatar active />
            </div>
          ) : (
            <InfiniteScroll
              dataLength={data.length}
              next={loadMoreData}
              hasMore={data.length < totalNormalUsers}
              loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
              endMessage={<Divider plain>You have reached the end 🤐</Divider>}
              scrollableTarget="scrollableDiv"
            >
              <List
                dataSource={data}
                renderItem={(item) => (
                  <List.Item key={item.id} style={{}}>
                    <List.Item.Meta
                      avatar={
                        <Avatar
                          src={
                            item.picture === undefined ? userImg : item.picture
                          }
                        />
                      }
                      title={
                        <div style={{ width: '50vw' }}>
                          <a
                            target={'_blank'}
                            href={`https://shouldertocryon.health/user/${item._id}`}
                          >
                            {item.name}
                          </a>
                          <p style={{ textTransform: 'capitalize' }}>
                            Account type: {item.role}
                          </p>
                          <p>{item.email}</p>
                          <p>
                            Driving:{' '}
                            {item.driving ? item.driving : 'Not provided'}
                          </p>
                          <p>
                            Email Verified: {JSON.stringify(item.emailVerified)}
                          </p>
                        </div>
                      }
                      description={
                        <div>
                          <p style={{ magin: 0 }}>
                            Singed up:{' '}
                            {moment(item.createdAt).format(
                              'YYYY-MM-DD hh:mm:ss a',
                            )}
                          </p>
                        </div>
                      }
                    />
                  </List.Item>
                )}
              />
            </InfiniteScroll>
          )}
        </div>
      </div>
    </AuthorizationLayout>
  )
}

const mapStateToProps = (state, ownState) => {
  return {
    auth: state.auth,
    user: state.user,
  }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Users)
