import React, { useEffect, useState, useRef } from 'react'
import {
  Col,
  Row,
  Dropdown,
  Typography,
  Input,
  Menu,
  Upload,
  Skeleton,
  Progress,
  Spin,
} from 'antd'
import { message as messageAntd } from 'antd'

import { connect, useDispatch } from 'react-redux'
import moment from 'moment'
import {
  PhoneOutlined,
  VideoCameraOutlined,
  DashOutlined,
  SearchOutlined,
  FrownOutlined,
  PaperClipOutlined,
  SendOutlined,
  LeftOutlined,
  DeleteOutlined,
} from '@ant-design/icons'
import Picker, { SKIN_TONE_MEDIUM_DARK } from 'emoji-picker-react'
import userimage from '../assets/images/man1.png'
import AuthorizationLayout from '../layout/AuthorizationLayout.jsx'
import Message from './Message'
import { Link, useParams, useNavigate } from 'react-router-dom'
import { API } from '../api/index'
import { TOOGLE_LIVE_NOTIFICATION, SET_INBOX } from '../redux/types'

import {
  getConsultantInfo,
  conversationHistory,
  inboxHistory,
  deleteConversation,
  makeAConversationSeen,
  startZoom,
} from '../redux/user/user.action'

import { uploadFiles } from '../utils/uploadFiles'

// let socket

const { Title } = Typography
const userImg = `https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png`

export const users = [
  { name: 'junaid', id: 1, image: userimage },
  { name: 'hasan', id: 2, image: userimage },
  { name: 'mahfuz', id: 3, image: userimage },
  { name: 'maruf', id: 4, image: userimage },
  { name: 'kasem', id: 5, image: userimage },
]

let usersTwo = Array(1).fill({
  name: 'kasem',
  id: Math.floor(Math.random() * (599 - 3 + 1)) + 3,
  image: userimage,
})

const Inbox = (props) => {
  const {
    getConsultantInfo,
    conversationHistory,
    inboxHistory,
    deleteConversation,
    makeAConversationSeen,
    startZoom,
    user,
    auth,
    socket,
  } = props
  const { tempConsultant } = user
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const initialSize = {
    width: window.innerWidth,
  }
  const [screen, setScreen] = useState(initialSize.width)
  const [collapse, setCollapse] = useState(false)
  const [ismobaile, setIsmobaile] = useState(true)
  const [id, setId] = useState(useParams().id) // User if from url, if any
  const [message, setMessage] = useState('')
  const [messages, setMessages] = useState([])
  const [showEmojiPicker, setShowEmojiPicker] = useState(false)
  const [inboxList, setInboxList] = useState([])
  const [remoteSearchResult, setRemoteSearchResult] = useState([])
  const [srcTxt, setSrcTxt] = useState('')
  const [fileProgress, setFileProgress] = useState(0) // upload progress

  useEffect(() => {
    const handlar = () => {
      setScreen(window.innerWidth)
    }
    window.addEventListener('resize', handlar)
    if (screen >= 768) {
      setIsmobaile(false)
    } else {
      setIsmobaile(true)
    }
    return () => {
      window.removeEventListener('resize', handlar)
    }
  }, [screen])
  function latestmessage(a, b) {
    return b.date - a.date
  }
  useEffect(() => {
    dispatch({ type: TOOGLE_LIVE_NOTIFICATION, payload: false }) // No need notification in this component

    inboxHistory()

    // TODO: Remove from private individual chat in future if needed
    // socket?.emit(
    //   'removeFromIndividualchat',
    //   { from: props.auth.user.user._id, to: id },
    //   (error) => {
    //     if (error) {
    //       alert(error)
    //     }
    //   },
    // )

    if (id) {
      makeAConversationSeen(id)
      // opposite user _id from url param
      getConsultantInfo(id) // of from url param

      conversationHistory(props.auth.user.user._id, id)

      // Create a unique private room for two individuals
      socket?.emit(
        'individualchat',
        { from: props.auth.user.user._id, to: id },
        (error) => {
          if (error) {
            alert(error)
          }
        },
      )

      // get live messages
      socket?.on('getPrivateMessage', (message) => {
        // console.log(message)
        // TODO: Making seen all the messages from the sending user ( do this later, currently not important here on each message)

        if (message.message.includes('https://us04web.zoom.us')) {
          window.open(message.message, '_blank').focus()
          return
        }

        setMessages((messages) => [...messages, message])
        // scrollToBottom()
      })
    }

    return () => {
      // if (socket) return socket.disconnect() // * Don't disconnect the socket here, because now we will use this socket connection to get live udpate on new message arival.

      dispatch({ type: TOOGLE_LIVE_NOTIFICATION, payload: true }) // true for all other routes, but not true for '/inbox' or '/inbox/*' routes
    }
  }, [id, socket]) // socket added later, because it takes time to get the socket connected and need to run the this useEffect function again after the socket is connected

  useEffect(() => {
    setMessages([])
    if (user.tempConversation && user.tempConversation.length > 0) {
      setMessages(user.tempConversation)
    }
    // setInboxList(user?.tempInbox)
    let tempUserList = user?.tempInbox
    tempUserList?.sort(latestmessage)
    setInboxList(tempUserList)
  }, [user])

  // Append "hasUnreadMessages" property with each object on inboxList (to show unread conversations)
  useEffect(() => {
    ;(async () => {
      if (user && user.tempInbox) {
        const resultArray = await Promise.all(
          user?.tempInbox?.map(async (el, idx) => {
            // console.log(el.from)
            // console.log(auth.user.user._id)
            // Send search query at backend
            const response = await API({
              Authorization: `Bearer ${auth?.user?.token}`,
            }).get(
              `/api/v1/user/isAnyUnreadMsg?from=${el?.from}&to=${auth?.user?.user?._id}`,
              {},
            )
            // console.log(response.data.hasUnreadMessages)
            return { ...el, hasUnreadMessages: response.data.hasUnreadMessages }
          }),
        )
        // console.log(resultArray)
        resultArray.sort(latestmessage)
        setInboxList(resultArray)
      }
      // dispatch({ type: SET_INBOX, payload: [] }) // * Don't manupulate the redux state, it will cause infinite loop of this useEffect
    })()
  }, [user.tempInbox])

  const videioAndAudioCall = () => {
    setCollapse((prevcollapse) => !prevcollapse)
  }

  const sendMessage = (event) => {
    event?.preventDefault()

    // console.log(message)

    if (message) {
      socket?.emit(
        'sendIndividualMessage',
        {
          from: props.auth.user.user._id,
          to: id,
          message,
        },
        () => setMessage(''),
      )
    }
  }

  const messagesEndRef = useRef(null)

  const scrollToBottom = () => {
    messagesEndRef?.current?.scrollIntoView({ behavior: 'smooth' }) // scrollIntoView maybe not available in /inbox has to id after it.
  }

  useEffect(scrollToBottom, [messages])

  const handleDeleteConversation = () => {
    deleteConversation(id, navigate, setMessages)
  }

  const onEmojiClick = (event, emojiObject) => {
    // console.log(emojiObject)
    // console.log(typeof emojiObject?.emoji)
    // todo: add emoji to message
    setMessage((el) => `${el} ${emojiObject?.emoji}`)
  }

  const handleSearch = async (txt) => {
    setRemoteSearchResult([])
    if (txt === ' ') return // more comprehensive checks can be added here later on.
    // Store text in search state
    setSrcTxt(txt)

    // Filter conversation list with search query
    if (user?.tempInbox && user.tempInbox.length > 0) {
      const rslt = user.tempInbox.filter((el, idx) => {
        if (el.name.toLowerCase().includes(txt.toLowerCase())) return el
      })
      // checking if not an empty string
      if (!!txt) {
        setInboxList(rslt)

        // Send search query at backend
        const response = await API({
          Authorization: `Bearer ${auth.user?.token}`,
        }).get(`/api/v1/user/searchUser?keyword=${txt}&user=true`, {})

        // store remote search results, to render as list
        if (response?.data?.users?.length > 0) {
          setRemoteSearchResult(response.data.users)
        }
      } else {
        setInboxList(user?.tempInbox)
        setRemoteSearchResult([])
      }
    }
  }

  const handleUploadImage = (options) => {
    uploadFiles(options, auth.user.token, id, setFileProgress)
    setMessages((messages) => [
      ...messages,
      {
        from: auth?.user?.user?._id,
        message: 'Please wait uploading  your content',
        date: Date.now(),
      },
    ])
    // remove the last element from messages array after 2 seconds ( trying to remove the dummy object that mentioned above )
    setTimeout(() => {
      setMessages((messages) => [...messages.slice(0, messages.length - 1)])
    }, 2000)
  }

  const menu = (
    <Menu style={{ padding: '10px' }}>
      <Menu.Item
        key="22"
        onClick={() => handleDeleteConversation()}
        icon={<DeleteOutlined style={{ fontSize: '16px' }} />}
        style={{ color: 'red' }}
      >
        Delete Conversation
      </Menu.Item>
    </Menu>
  )

  return (
    <AuthorizationLayout>
      <div style={styles.wraper}>
        <Row>
          <Col
            xs={{ span: ismobaile && !id ? 24 : 0 }}
            md={{ span: 6 }}
            lg={{ span: 6 }}
            style={styles.leftbox}
          >
            <div style={styles.userList}>
              {ismobaile ? (
                <div>
                  <h1
                    style={{ margin: '0px', color: 'white', fontSize: '26px' }}
                  >
                    Messages
                  </h1>
                  <div>
                    <form
                      style={{ position: 'relative', marginBottom: '10px' }}
                      onSubmit={(e) => {
                        e.preventDefault()
                      }}
                    >
                      <Input
                        prefix={
                          <SearchOutlined className="site-form-item-icon" />
                        }
                        style={{
                          width: '100%',
                          padding: '7px 10px',
                          border: 'none',
                          outline: 'none',
                          borderRadius: '20px',
                          backgroundColor: '#ffffff',
                        }}
                        placeholder="Search"
                        type="text"
                        onChange={(evt) => handleSearch(evt.target.value)}
                      />
                    </form>
                  </div>
                </div>
              ) : (
                <div style={styles.searchBoxDesktop}>
                  <h1
                    style={{
                      margin: '0px',
                      color: 'white',
                      fontSize: '20px',
                    }}
                  >
                    Messages
                  </h1>
                  <div>
                    <form
                      style={{ position: 'relative', marginBottom: '10px' }}
                      onSubmit={(e) => {
                        e.preventDefault()
                      }}
                    >
                      <Input
                        prefix={
                          <SearchOutlined className="site-form-item-icon" />
                        }
                        style={{
                          width: '100%',
                          padding: '7px 10px',
                          border: 'none',
                          outline: 'none',
                          borderRadius: '20px',
                          backgroundColor: '#ffffff',
                        }}
                        placeholder="Search"
                        type="text"
                        onChange={(evt) => handleSearch(evt.target.value)}
                      />
                    </form>
                  </div>
                </div>
              )}
              <div>
                {/* {JSON.stringify(user.tempInbox?.length)}
                {JSON.stringify(user.tempInbox)} */}

                {user.tempInbox?.length === 0 && (
                  <>
                    <h1 style={{ marginTop: 30, color: '#fff' }}>
                      No conversations yet
                    </h1>
                    <p style={{ marginTop: 30, color: '#fff' }}>
                      Find people and start a conversation
                    </p>
                  </>
                )}
                {srcTxt && (
                  <h1 style={{ color: '#fff' }}>
                    Search result for "{srcTxt}"
                  </h1>
                )}
                {!inboxList ? (
                  <div className="spiner-center">
                    <Skeleton avatar active />
                  </div>
                ) : (
                  inboxList?.map((user) => (
                    <div style={styles.userInfo} key={user._id}>
                      <div>
                        <img
                          style={styles.userImage}
                          src={
                            user?.picture === undefined
                              ? userImg
                              : user?.picture
                          }
                          alt="N/A"
                        />
                      </div>

                      {/* <Link
                        to={`/inbox/${user.oppositeUser}`}
                        onClick={() => setId(user.oppositeUser)}
                        style={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      > */}
                      <a
                        href={`/inbox/${user.oppositeUser}`}
                        onClick={() => setId(user.oppositeUser)}
                        style={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div
                          style={{
                            paddingLeft: 3,
                          }}
                        >
                          <h3
                            style={{
                              margin: '0px',
                              color: 'white',
                            }}
                          >
                            {user.name}
                          </h3>

                          <div style={styles.messageAndTime}>
                            <p
                              style={{
                                margin: '0px',
                                fontSize: '12px',
                                color: '#d3d3d3',
                                width: '100%',
                              }}
                            >
                              {user.message.substring(0, 15)}
                            </p>
                          </div>
                        </div>
                        <p
                          style={{
                            margin: '0px',
                            color: '#d3d3d3',
                            fontSize: 11,
                            display: 'flex',
                          }}
                        >
                          {moment(user.date).fromNow()}
                          {user.hasUnreadMessages && (
                            <div style={styles.unreadCircle} />
                          )}
                        </p>
                      </a>
                      {/* </Link> */}
                    </div>
                  ))
                )}

                {/* remote users list rendered from backend, on inbox search */}
                {remoteSearchResult.length > 0 && (
                  <h1 style={{ color: '#fff' }}>Other users</h1>
                )}
                {remoteSearchResult?.map((user, idx) => {
                  return (
                    <div style={styles.userInfo} key={user._id}>
                      <div>
                        <img
                          style={styles.userImage}
                          src={
                            user?.picture === undefined
                              ? userImg
                              : user?.picture
                          }
                          alt="N/A"
                        />
                      </div>

                      <Link
                        to={`/inbox/${user._id}`}
                        onClick={() => setId(user._id)}
                        style={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div
                          style={{
                            paddingLeft: 3,
                          }}
                        >
                          <h3
                            style={{
                              margin: '0px',
                              color: 'white',
                            }}
                          >
                            {user.name}
                          </h3>
                        </div>
                      </Link>
                    </div>
                  )
                })}
              </div>
            </div>
          </Col>

          <Col xs={{ span: 24 }} md={{ span: 18 }} lg={{ span: 18 }}>
            {id ? (
              <>
                <div style={styles.chatBoxFrofile}>
                  <div>
                    <div
                      style={{
                        display: 'flex',
                        // border: '1px solid red'
                      }}
                    >
                      <Link
                        style={{ color: 'white', textdecoration: 'none' }}
                        to={`/inbox`}
                        onClick={() => setId(null)}
                      >
                        <div
                          style={{
                            backgroundColor: '#777777',
                            height: '40px',
                            width: '40px',
                            borderRadius: '50%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            cursor: 'pointer',
                            padding: '20px',
                            marginRight: '10px',
                          }}
                        >
                          <h1
                            style={{
                              margin: '0px',
                              color: 'white',
                              fontWeight: 'bold',
                            }}
                          >
                            <LeftOutlined />
                          </h1>
                        </div>
                      </Link>
                      <img
                        style={styles.userImage}
                        src={
                          tempConsultant?.picture === undefined
                            ? userImg
                            : tempConsultant?.picture
                        }
                        alt={`img`}
                      />

                      {/* name div */}
                      <div style={{ display: 'inline' }}>
                        <Link to={`/user/${tempConsultant?._id}`}>
                          <h1
                            style={{
                              lineHeight: '22px',
                              marginBottom: '2px',
                              color: '#3B9339',
                              paddingLeft: '8px',
                              fontWeight: 'bold',
                            }}
                          >
                            {tempConsultant ? tempConsultant.name : ''}
                          </h1>
                        </Link>
                        <p
                          style={{
                            color: '#7D7987',
                            marginBottom: '2px',
                            paddingLeft: '8px',
                          }}
                        >
                          Active now
                        </p>
                      </div>
                    </div>
                    {/* <span style={{ margin: '0px' }}>Online 8 min ago</span> */}
                  </div>

                  <div className="flex space-x-2">
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        messageAntd.info(
                          'You must have zoom installed in your device, to make the call happen.',
                          10,
                        )
                        startZoom(socket, id)
                      }}
                    >
                      <h3
                        style={{
                          fontSize: '24px',
                          color: 'white',
                          margin: '0px',
                        }}
                      >
                        <PhoneOutlined />
                      </h3>
                    </div>
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        messageAntd.info(
                          'You must have zoom installed in your device, to make the call happen.',
                          10,
                        )
                        startZoom(socket, id)
                      }}
                    >
                      <h3
                        style={{
                          fontSize: '24px',
                          color: 'white',
                          margin: '0px',
                        }}
                      >
                        <VideoCameraOutlined />
                      </h3>
                    </div>
                    <div className="border-0 cursor-pointer">
                      <Dropdown overlay={menu} placement="bottomLeft">
                        <h3
                          style={{
                            fontSize: 22,
                            color: 'white',
                            margin: 0,
                            fontWeight: 'bold',
                          }}
                        >
                          <DashOutlined />
                        </h3>
                      </Dropdown>
                    </div>
                  </div>
                </div>
                <div style={styles.chatBox}>
                  {messages.length === 0 && (
                    <p style={{ textAlign: 'center' }}>
                      Send message to start conversation
                    </p>
                  )}

                  {messages.length === 0 && user.tempConversationLoading && (
                    <div
                      style={{
                        margin: '20px 0',
                        padding: '30px 50px',
                        textAlign: 'center',
                      }}
                    >
                      <Spin size="large" />
                    </div>
                  )}

                  {/* messages list */}
                  {messages.map((el, idx) => {
                    return <Message msg={el} key={el._id} />
                  })}

                  {/* <div
                    style={{
                      border: '1px solid red',
                      width: '100%',
                      // minHeight: 200,
                      position: 'relative',
                    }}
                  > */}
                  {showEmojiPicker && (
                    <Picker
                      onEmojiClick={onEmojiClick}
                      disableAutoFocus={true}
                      skinTone={SKIN_TONE_MEDIUM_DARK}
                      groupNames={{ smileys_people: 'PEOPLE' }}
                      native
                      pickerStyle={{ border: '1px solid blue', width: '60%' }}
                    />
                  )}
                  {/* </div> */}

                  <div style={{ textAlign: 'center', marginTop: '50px' }}>
                    <div style={{ position: 'relative' }}>
                      <input
                        style={{
                          border: 'none',
                          width: '100%',
                          padding: '6px 15px',
                          borderRadius: '20px',
                          outline: 'none',
                          backgroundColor: '#777777',
                          color: 'white',
                        }}
                        type="text"
                        placeholder="Your thoughts here..."
                        value={message}
                        onChange={({ target: { value } }) => setMessage(value)}
                        onKeyPress={(event) =>
                          event.key === 'Enter' ? sendMessage(event) : null
                        }
                        className="input-message"
                      />

                      <div
                        style={{
                          display: 'flex',
                          position: 'absolute',
                          right: '25px',
                          top: '2px',
                        }}
                      >
                        <h4
                          style={{
                            cursor: 'pointer',
                            marginRight: '10px',
                            fontSize: '18px',
                            color: 'white',
                          }}
                        >
                          <FrownOutlined
                            onClick={() => {
                              setShowEmojiPicker(!showEmojiPicker)
                            }}
                          />
                        </h4>
                        <h4
                          style={{
                            marginRight: '10px',
                            fontSize: '18px',
                          }}
                        >
                          <Upload
                            showUploadList={false}
                            beforeUpload={beforeUploadImage}
                            customRequest={handleUploadImage}
                          >
                            <PaperClipOutlined
                              style={{
                                color: '#fff',
                                cursor: 'pointer',
                                fontSize: '18px',
                              }}
                              // onClick={() =>
                              //   console.log('this feature coming soon')
                              // }
                            />
                          </Upload>
                        </h4>
                        <h4
                          style={{
                            cursor: 'pointer',
                            fontSize: '18px',
                            color: 'white',
                          }}
                        >
                          <SendOutlined onClick={() => sendMessage(null)} />
                        </h4>
                      </div>
                    </div>
                    {fileProgress > 0 && <Progress percent={fileProgress} />}
                  </div>

                  {/* using this div to scroll to bottom, without any external package */}
                  <div ref={messagesEndRef} />
                </div>
              </>
            ) : (
              <div style={styles.noSelectContainer}>
                <h1>Select a message to start conversation</h1>
              </div>
            )}
          </Col>
        </Row>
      </div>
    </AuthorizationLayout>
  )
}

const styles = {
  wraper: {
    margin: '20px',
    backgroundColor: '#A5C8A8',
    borderRadius: '10px',
  },
  searchBoxDesktop: {
    // display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'space-between',
  },
  searchBoxIcon: {
    backgroundColor: 'white',
    height: '40px',
    width: '40px',
    borderRadius: '50%',
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  userList: {
    backgroundColor: '#419945',
    overflowY: 'auto',
    height: '80vh',
    padding: '20px',
    color: 'white',
    borderTopLeftRadius: '10px',
    borderBottomLeftRadius: '10px',
    // border: '1px solid yellow',
  },
  userInfo: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px 0px',
    width: '100%',
    // border: '1px solid red',
  },
  userImage: {
    width: '45px',
    height: '45px',
    borderRadius: '50%',
    padding: '2px',
    border: '1px solid white', //temporary border style added,
  },
  messageAndTime: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  chatBox: {
    height: '80vh',
    width: '100%',
    paddingLeft: 10,
    paddingRight: 10,
    overflowY: 'auto',
  },
  noSelectContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  chatBoxFrofile: {
    display: 'flex',
    justifyContent: 'space-between',
    height: 60,
    backgroundColor: '#A5C8A8',
    padding: 5,
  },
  inbox: {
    backgroundColor: 'white',
  },
  leftbox: {
    backgroundColor: '#419945',
    borderRadius: '10px 0px 0px 10px',
    paddingBottom: '5px',
  },
  unreadCircle: {
    height: 13,
    width: 13,
    backgroundColor: 'rgb(255, 77, 79)',
    borderRadius: '50%',
    marginLeft: 2,
    marginTop: 3,
  },
}

const mapStateToProps = (state, ownState) => {
  return {
    auth: state.auth,
    user: state.user,
    socket: state.socket.socket,
  }
}

const mapDispatchToProps = {
  getConsultantInfo,
  conversationHistory,
  inboxHistory,
  deleteConversation,
  makeAConversationSeen,
  startZoom,
}

export default connect(mapStateToProps, mapDispatchToProps)(Inbox)

function beforeUploadImage(file) {
  // console.log(file)
  // const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
  // if (!isJpgOrPng) {
  //   message.error('You can only upload JPG/PNG file!')
  // }
  const isLt20M = file.size / 1024 / 1024 < 20
  if (!isLt20M) {
    messageAntd.error('File must be smaller than 20MB!')
  }
  // return isJpgOrPng && isLt20M
  return isLt20M
}
