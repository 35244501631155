import React, { useEffect, useState } from 'react'
import {
  Modal,
  Button,
  Radio,
  Space,
  Input,
  List,
  Skeleton,
  Avatar,
  Spin,
} from 'antd'
import { message as messageAntd } from 'antd'
import { useDispatch, connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { MessageOutlined, PlusOutlined } from '@ant-design/icons'

import { API } from '../../api'

const userImg = `https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png`
const { Search } = Input

function CreateGroupModal({ visible, setVisible, ...props }) {
  const [data, setData] = useState([]) // total users data
  const [loading, setLoading] = useState(false) // loading spinner
  const dispatch = useDispatch()

  const handleCancel = () => {
    setVisible(false)
  }

  const groupInfo = async (obj) => {
    try {
      setLoading(true)
      // GET conversationHistory request
      const response = await API({
        Authorization: `Bearer ${obj.token}`,
      }).get(`/api/v1/user/groupInfo?groupId=${obj.id}`, {})

      console.log(response.data)
      setData(response.data.groupInfo[0]?.users)
      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (visible) {
      groupInfo({ id: props.groupId, token: props.auth.user?.token })
    }
  }, [visible])

  return (
    <Modal
      title="All members"
      visible={visible}
      // onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
    >
      <Space direction="vertical" style={{ width: '100%' }}>
        {data.length > 0 ? (
          <div
            id="scrollableDiv"
            style={{
              overflow: 'auto',
              padding: '0 16px',
              border: '1px solid rgba(140, 140, 140, 0.35)',
            }}
          >
            {loading ? (
              <div className="spiner-center">
                <Skeleton avatar active />
              </div>
            ) : (
              <List
                dataSource={data}
                renderItem={(item) => (
                  <List.Item key={item.id}>
                    <List.Item.Meta
                      avatar={
                        <Avatar
                          src={
                            item.picture === undefined ? userImg : item.picture
                          }
                        />
                      }
                      title={item.name}
                      description={`${item.region ? `${item.region}` : ''}`}
                    />
                    <div>
                      <p>{item.email}</p>
                    </div>
                  </List.Item>
                )}
              />
            )}
          </div>
        ) : (
          <>{loading && <Spin />}</>
        )}
      </Space>
    </Modal>
  )
}

const mapStateToProps = (state, ownState) => {
  return {
    auth: state.auth,
  }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(CreateGroupModal)
