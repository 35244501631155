import { Layout, Row, Col } from 'antd'
import {
  FacebookFilled,
  TwitterSquareFilled,
  LinkedinFilled,
  YoutubeFilled,
} from '@ant-design/icons'
import { Link } from 'react-router-dom'
const { Footer } = Layout

export const Footers = () => {
  return (
    <Footer style={{ textAlign: 'left' }}>
      <Row id="footer" gutter={32} style={{ padding: '0px 10px 0px 10px' }}>
        <Col xs={24} sm={8} md={8} lg={8}>
          <div style={{}}>
            <Link to="/">
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                  // border: '1px solid red',
                }}
              >
                <img
                  style={{ height: '110px' }}
                  src="https://stco.s3.amazonaws.com/all/timestamp-1644153797678.png"
                  alt=""
                />
                <img
                  src="https://stco.s3.amazonaws.com/all/timestamp-1644086179888.png"
                  alt=""
                />
              </div>
            </Link>
          </div>
        </Col>
        <Col xs={24} sm={8} md={8} lg={8}>
          <p
            style={{
              fontWeight: 200,
              marginTop: 10,
              fontSize: '1rem',
              textAlign: 'center',
            }}
          >
            Shoulder to Cry On is proudly brought to you by the Ark-am Team
          </p>
          <p
            style={{
              margin: 0,
              fontWeight: 200,
              fontSize: '1rem',
              textAlign: 'center',
            }}
          >
            <a href="https://www.ark-am.com" target={'_blank'}>
              www.ark-am.com
            </a>
          </p>
          <p
            style={{
              margin: 0,
              fontWeight: 200,
              fontSize: '1rem',
              textAlign: 'center',
              marginBottom: 30,
            }}
          >
            <a href="https://www.ark-am.com/contact" target={'_blank'}>
              © Ark-am 2021
            </a>
          </p>
        </Col>

        <Col xs={24} sm={8} md={8} lg={8}>
          {/* <div
            style={{
              // border: '1px solid red',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <p style={{ margin: 0, fontSize: 17 }}>Connect us at</p>
            <ul>
              <li>
                <a href="https://www.facebook.com/arkam2021/" target={'_blank'}>
                  <FacebookFilled className="social-icon" />
                </a>
              </li>
              <li>
                <a href="https://twitter.com/ark_am_2021" target={'_blank'}>
                  <TwitterSquareFilled className="social-icon" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/80680806/admin/"
                  target={'_blank'}
                >
                  {' '}
                  <LinkedinFilled className="social-icon" />
                </a>
              </li>
              <li>
                <a href="#" target={'_blank'}>
                  {' '}
                  <YoutubeFilled className="social-icon" />
                </a>
              </li>
            </ul>
          </div> */}
        </Col>
      </Row>
    </Footer>
  )
}
