import React, { useState } from 'react'
import {
  Modal,
  Button,
  Radio,
  Space,
  Input,
  List,
  Skeleton,
  Avatar,
} from 'antd'
import { message as messageAntd } from 'antd'
import { useDispatch, connect } from 'react-redux'
import { MessageOutlined, PlusOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'

import { API } from '../../api'

const userImg = `https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png`
const { Search } = Input

function SignupSuccessModal({ visible, setVisible, ...props }) {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleCancel = () => {
    setVisible(false)
  }

  const onSubmit = (e) => {
    navigate('/')
  }

  return (
    <Modal
      title="Signup success"
      visible={visible}
      // onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
    >
      <h1>
        Please visit your email inbox to verify and login to Shoulder To Cry On
        (STCO)
      </h1>
      <Space direction="vertical" style={{ width: '100%' }}>
        <Button
          onClick={onSubmit}
          type="primary"
          block
          style={{ marginTop: 20 }}
        >
          Ok
        </Button>
      </Space>
    </Modal>
  )
}

const mapStateToProps = (state, ownState) => {
  return {
    auth: state.auth,
  }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(SignupSuccessModal)
